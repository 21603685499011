import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import '../css/Tools.css'

// Utility function to highlight search term
const highlightText = (text, searchTerm) => {
    if (!searchTerm.trim()) return text;

    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return text.split(regex).map((part, index) =>
        regex.test(part) ? <mark key={index} className="tools-highlight">{part}</mark> : part
    );
};

const Tools = () => {

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = useCallback((e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
    }, []);

    useEffect(() => {
    }, [searchTerm]);

    // Define tools data using useMemo
    const toolsData = useMemo(() => [
        {
            link: "/create-story",
            imgPath: "/images/tools/story.jpg",
            title: "Create a Story",
            description: "story generator creative writing fiction narrative plot characters setting genre inspiration AI-generated"
        },
        {
            link: "/blog/tutorials/design-logo",
            imgPath: "/images/tools/design-logo.jpg",
            title: "Create a Logo",
            description: "logo design branding visual identity graphic design company logo business logo custom logo AI-generated"
        },
        {
            link: "/blog-generator",
            imgPath: "/images/tools/blog.jpg",
            title: "Write a Blog",
            description: "blog writing content creation article generator SEO-friendly posts marketing copywriting AI-assisted"
        },
        {
            link: "/generate-recipe",
            imgPath: "/images/tools/recipe.jpg",
            title: "Find a Recipe",
            description: "recipe finder cooking meal planner food ingredients cuisine dietary restrictions culinary inspiration"
        },
        {
            link: "/prepare-interview",
            imgPath: "/images/tools/interview.jpg",
            title: "Prepare Interview",
            description: "interview preparation job search career advice questions answers tips mock interview practice"
        },
        {
            link: "/diet-planner",
            imgPath: "/images/tools/diet.jpg",
            title: "Plan Your Diet",
            description: "diet plan nutrition meal planning healthy eating weight loss fitness goals personalized diet"
        },
        {
            link: "/generate-image",
            imgPath: "/images/tools/image-generation.jpeg",
            title: "Generate Image",
            description: "image generation AI art custom visuals graphic design illustration digital art creative tool"
        },
        {
            link: "/workout-planner",
            imgPath: "/images/tools/workout.jpg",
            title: "Plan Gym Workout",
            description: "workout plan fitness routine exercise program strength training cardio personalized fitness goals"
        },
        {
            link: "/assignment-maker",
            imgPath: "/images/tools/assignment.jpg",
            title: "Complete an Assignment",
            description: "assignment help homework assistance academic writing research aid study tool educational support"
        },
        {
            link: "/startup-name-generator",
            imgPath: "/images/tools/startup-name.jpg",
            title: "Look for Startup Names",
            description: "startup names business naming brand ideas company names creative naming generator entrepreneurship"
        },
        {
            link: "/domain-name-ideas",
            imgPath: "/images/tools/domain-name.jpg",
            title: "Domain Name Ideas",
            description: "domain names website naming web address URL suggestions online presence branding internet identity"
        },
        {
            link: "/nickname-finder",
            imgPath: "/images/tools/nickname.jpg",
            title: "Find Nick Names",
            description: "nickname generator aliases pet names usernames online personas creative naming fun names"
        },
        {
            link: "/baby-name-suggestions",
            imgPath: "/images/tools/baby-names.jpg",
            title: "Baby Name Suggestions",
            description: "baby names child naming parenting name meanings cultural names trendy names unique names"
        },
        {
            link: "/gift-suggestions",
            imgPath: "/images/tools/gift-suggestions.jpg",
            title: "Gift Suggestions",
            description: "gift ideas present finder occasion-based gifts personalized gifts shopping helper thoughtful presents"
        },
        {
            link: "/dream-analyzer",
            imgPath: "/images/tools/dream-analyzer.jpg",
            title: "Analyze Your Dreams",
            description: "dream interpretation sleep analysis subconscious meanings psychology symbolism personal insights"
        },
        {
            link: "/hobby-finder",
            imgPath: "/images/tools/hobby-finder.jpg",
            title: "Find Hobbies For You",
            description: "hobby suggestions leisure activities pastime finder interests exploration personal development skills"
        },
        {
            link: "/pet-name-ideas",
            imgPath: "/images/tools/pet-names.jpg",
            title: "Pet Name Ideas",
            description: "pet names animal naming cute names creative pet names dog names cat names unique pet names"
        },
        {
            link: "/startup-ideas",
            imgPath: "/images/tools/startup-ideas.jpg",
            title: "Startup Ideas",
            description: "business ideas entrepreneurship innovation startup concepts market trends business opportunities"
        }
    ], []);

    // Filter tools based on search term using useMemo
    const filteredTools = useMemo(() => {
        if (!searchTerm.trim()) {
            return toolsData;
        }
        return toolsData.filter(tool =>
            tool.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            tool.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, toolsData]);

    return (
        <div>
            <div className='tools-tools-heading'>
                <h1 className='tools-tools-title'>All tools</h1>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search tools..."
                    className="tools-tools-search"
                />
            </div>
            <div className="tools-tools-container">
                <Link to="/generate-image" className="tools-featured-tool">
                    <img src="/images/tools/image-generation.jpeg" alt="generate_image" className="tools-featured-image" />
                    <div className="tools-featured-overlay">
                        <h3 className="tools-featured-title">Generate Image</h3>
                    </div>
                    <span className="tools-featured-tag">with ai prompt improvement</span>
                </Link>
                <div className="tools-tools-grid">
                    {filteredTools.map((tool, index) => (
                        <Link to={tool.link} key={index} className="tools-tool-card">
                            <img src={tool.imgPath} alt={tool.title} className="tools-tool-image" />
                            <div className="tools-tool-content">
                                <h3 className="tools-tool-title">{highlightText(tool.title, searchTerm)}</h3>
                                <span className="tools-tool-subtitle">with related ai visuals</span>
                            </div>
                        </Link>
                    ))}
                </div>
                {filteredTools.length === 0 && <p className="tools-no-results">No tools found matching your search.</p>}
            </div>
        </div>
    );
}

export default Tools;
