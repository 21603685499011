import React, { useEffect } from 'react';
import './App.css';
import Header from './Components/Header';
import Home from './Components/Home';
import BlogGenerator from './Components/Tools/BlogGenerator';
import RecipeGenerator from './Components/Tools/RecipeGenerator';
import InterviewPreparator from './Components/Tools/InterviewPreprator';
import ScrollToTop from './Components/ScrollToTop';
import DietPlanner from './Components/Tools/DietPlanner';
import WorkoutPlanner from './Components/Tools/WorkoutPlanner';
import GoogleAnalytics from './Components/Analytics/GoogleAnalytics';
import { setCookie, getCookie } from './utils/cookies';
import AssignmentMaker from './Components/Tools/AssignmentMaker';
import ImageGenerator from './Components/Tools/ImageGenerator'
import StartupNameGenerator from './Components/Tools/StartupNameGenerator';
import DomainNameGenerator from './Components/Tools/DomainNameGenerator';
import NicknameGenerator from './Components/Tools/NicknameGenerator';
import GiftSuggestionGenerator from './Components/Tools/GiftSuggestionGenerator';
import BabyNameGenerator from './Components/Tools/BabyNameGenerator';
import DreamAnalyzer from './Components/Tools/DreamAnalyzer';
import HobbyFinder from './Components/Tools/HobbyFinder';
import PetNameGenerator from './Components/Tools/PetNameGenerator';
import StartupIdeaGenerator from './Components/Tools/StartupIdeaGenerator';
import GoogleSignIn from './Components/Auth/GoogleSignIn'
import PrivateRoute from './Components/Auth/PrivateRoute';
import { isImageGenerationLimited } from './utils/cookies';
import Profile from './Components/User/Profile';
import Explore from './Components/User/Explore';
import About from './Components/About';
import Tools from './Components/Tools';
import ImageDetailView from './Components/ImageDetailView';
import StoryGenerator from './Components/Tools/StoryGenerator';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Blog from './Components/Blog';
import ContentViewer from './Components/ContentViewer';
import AdminDashboard from './Components/Admin/AdminDashboard';
import AdminRoute from './Components/Auth/AdminRoute';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  useEffect(() => {
    const userSession = getCookie('user_session');

    if (!userSession) {
      const newSessionId = `session_${Math.random().toString(36).substring(2)}`;
      setCookie('user_session', newSessionId, 15);
    }
  }, []);

  return (
    <>
      <Router>
        <GoogleAnalytics />
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/login" element={<GoogleSignIn />} />

          <Route element={<PrivateRoute />}>
            <Route path='/profile' element={<Profile />} />
            {isImageGenerationLimited() && (
              <Route path='/generate-image' element={<ImageGenerator />} />
            )}
          </Route>

          <Route element={<AdminRoute />}>
            <Route path="/admin" element={<AdminDashboard />} />
          </Route>

          {!isImageGenerationLimited() && (
            <Route path='/generate-image' element={<ImageGenerator />} />
          )}
          <Route path="/home" element={<Home />} />
          <Route path="/blog-generator" element={<BlogGenerator />} />
          <Route path='/generate-recipe' element={<RecipeGenerator />} />
          <Route path='/prepare-interview' element={<InterviewPreparator />} />
          <Route path='/diet-planner' element={<DietPlanner />} />
          <Route path='/workout-planner' element={<WorkoutPlanner />} />
          <Route path='/assignment-maker' element={<AssignmentMaker />} />
          <Route path='/generate-image' element={<ImageGenerator />} />
          <Route path='/startup-name-generator' element={<StartupNameGenerator />} />
          <Route path='/domain-name-ideas' element={<DomainNameGenerator />} />
          <Route path='/nickname-finder' element={<NicknameGenerator />} />
          <Route path='/gift-suggestions' element={<GiftSuggestionGenerator />} />
          <Route path='/baby-name-suggestions' element={<BabyNameGenerator />} />
          <Route path='/dream-analyzer' element={<DreamAnalyzer />} />
          <Route path='/hobby-finder' element={<HobbyFinder />} />
          <Route path='/pet-name-ideas' element={<PetNameGenerator />} />
          <Route path='/startup-ideas' element={<StartupIdeaGenerator />} />
          <Route path='/explore' element={<Explore />} />
          <Route path="/profile/:username" element={<Profile />} />
          <Route path="/create" element={<Tools />} />
          <Route path="/image/:imageId" element={<ImageDetailView />} />
          <Route path="/create-story" element={<StoryGenerator />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:category/:post" element={<Blog />} />
          <Route path="/content" element={<ContentViewer />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;