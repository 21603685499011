export const realFlyerData = [
    {
        id: 1,
        imageUrl: '../images/flyer/join-team.jpg',
        title: 'Join Our Creative Team',
        description: 'Become part of a diverse team of skilled individuals.',
        link: 'https://devsaura.com/join-us',
        buttonName: 'Learn More',
        openInNewTab: true
    },
    {
        id: 2,
        imageUrl: '../images/flyer/follow-us-instagram.jpg',
        title: 'Follow Us on Instagram',
        description: 'Stay updated with tips and the latest features.',
        link: 'https://instagram.com/devsaura',
        buttonName: 'Visit Instagram',
        openInNewTab: true
    },
    {
        id: 3,
        imageUrl: '../images/flyer/place-your-ad-here.jpg',
        title: 'Place Your Ad Here',
        description: 'Promote your brand to a growing AI audience.',
        link: '/blog/other/advertisement',
        buttonName: 'Learn More',
        openInNewTab: false
    },
    {
        id: 4,
        imageUrl: '../images/flyer/place-your-ad-here.jpg',
        title: 'Place Your Ad Here',
        description: 'Promote your brand to a growing AI audience.',
        link: '/blog/other/advertisement',
        buttonName: 'Learn More',
        openInNewTab: false
    },
    {
        id: 5,
        imageUrl: '../images/flyer/place-your-ad-here.jpg',
        title: 'Place Your Ad Here',
        description: 'Promote your brand to a growing AI audience.',
        link: '/blog/other/advertisement',
        buttonName: 'Learn More',
        openInNewTab: false
    },
    {
        id: 6,
        imageUrl: '../images/flyer/learn-with-us.jpg',
        title: 'Learn With Us',
        description: 'Develop AI-integrated websites with our guidance.',
        link: 'https://devsaura.com/contact-us',
        buttonName: 'Contact Us',
        openInNewTab: false
    }
];

export const getRandomFlyer = () => {
    const randomIndex = Math.floor(Math.random() * realFlyerData.length);
    return realFlyerData[randomIndex];
};
