import { useState, useCallback } from 'react';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import useMessage from '../../hooks/useNotify';

const useProfileHandlers = () => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [followLoading, setFollowLoading] = useState(false);
    const [isEditingBio, setIsEditingBio] = useState(false);
    const [isEditingSocialLinks, setIsEditingSocialLinks] = useState(false);
    const { setMessage } = useMessage();

    const handleUpdateBio = useCallback(async (newBio) => {
        try {
            const response = await authenticatedApiCall('/update-bio', 'POST', { bio: newBio });
            if (response.success) {
                setIsEditingBio(false);
                setMessage('Bio updated successfully!', 'success');
                return true;
            } else {
                setMessage('Failed to update bio', 'error');
                return false;
            }
        } catch (error) {
            setMessage(`Error updating bio: ${error.message}`, 'error');
            return false;
        }
    }, [setMessage]);

    const handleUpdateSocialLinks = useCallback(async (newLinks) => {
        try {
            const response = await authenticatedApiCall('/update-social-links', 'POST', { socialLinks: newLinks });
            if (response.success) {
                setIsEditingSocialLinks(false);
                setMessage('Social links updated successfully!', 'success');
                return true;
            } else {
                setMessage('Failed to update social links', 'error');
                return false;
            }
        } catch (error) {
            setMessage(`Error updating social links: ${error.message}`, 'error');
            return false;
        }
    }, [setMessage]);

    const toggleProfileVisibility = useCallback(async () => {
        try {
            const response = await authenticatedApiCall('/toggle-profile-visibility', 'POST');
            if (response.success) {
                setMessage('Profile preference updated!', 'success');
                return response.is_public;
            } else {
                setMessage('Failed to toggle profile visibility', 'error');
                return null;
            }
        } catch (error) {
            setMessage(`Error toggling profile visibility: ${error.message}`, 'error');
            return null;
        }
    }, [setMessage]);

    const handleDeleteImage = useCallback(async (imageId) => {
        try {
            const response = await authenticatedApiCall('/delete-saved-image', 'DELETE', { imageId });
            if (response.success) {
                return true;
            } else {
                setMessage('Failed to delete image', 'error');
                return false;
            }
        } catch (error) {
            setMessage(`Error deleting image: ${error.message}`, 'error');
            return false;
        }
    }, [setMessage]);

    const handleDeleteAccount = useCallback(async () => {
        setIsDeleting(true);
        try {
            const response = await authenticatedApiCall('/delete-account', 'DELETE');
            if (response.success) {
                return true;
            } else {
                setMessage('Failed to delete account', 'error');
                return false;
            }
        } catch (error) {
            setMessage(`Error deleting account: ${error.message}`, 'error');
            return false;
        } finally {
            setIsDeleting(false);
        }
    }, [setMessage]);

    const handleFollow = useCallback(async (username) => {
        setFollowLoading(true);
        try {
            const response = await authenticatedApiCall(`/follow/${username}`, 'POST');
            if (response.message) {
                setMessage(response.message, 'success');
                return true;
            }
            return false;
        } catch (error) {
            setMessage(`Error following user: ${error.message}`, 'error');
            return false;
        } finally {
            setFollowLoading(false);
        }
    }, [setMessage]);

    const handleUnfollow = useCallback(async (username) => {
        setFollowLoading(true);
        try {
            const response = await authenticatedApiCall(`/unfollow/${username}`, 'POST');
            if (response.message) {
                setMessage(response.message, 'success');
                return true;
            }
            return false;
        } catch (error) {
            setMessage(`Error unfollowing user: ${error.message}`, 'error');
            return false;
        } finally {
            setFollowLoading(false);
        }
    }, [setMessage]);

    return {
        isDeleting,
        followLoading,
        isEditingBio,
        setIsEditingBio,
        isEditingSocialLinks,
        setIsEditingSocialLinks,
        handleUpdateBio,
        handleUpdateSocialLinks,
        toggleProfileVisibility,
        handleDeleteImage,
        handleDeleteAccount,
        handleFollow,
        handleUnfollow,
    };
};

export default useProfileHandlers;