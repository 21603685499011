import { useState, useCallback } from 'react';
import { downloadImage } from '../../utils/image/imageDownload';
import { copyPromptToClipboard, copyMarkdownLinkToClipboard } from '../../utils/image/copyPrompt';
import { reportImage } from '../../utils/image/reportImage';

const useImageHandlers = () => {
  const [buttonStates, setButtonStates] = useState({});
  const [error, setError] = useState(null);

  const handleDownload = useCallback(async (imageUrl, id, imageTitle) => {
    setButtonStates(prev => ({ ...prev, [id]: { download: 'loading' } }));
    try {
      await downloadImage(imageUrl, imageTitle);
      setButtonStates(prev => ({ ...prev, [id]: { download: 'success' } }));
      setTimeout(() => {
        setButtonStates(prev => ({ ...prev, [id]: { download: 'default' } }));
      }, 2000);
      return { success: true, message: 'Image downloaded successfully' };
    } catch (error) {
      setButtonStates(prev => ({ ...prev, [id]: { download: 'default' } }));
      setError('Failed to download image: ' + error.message);
      return { success: false, message: 'Failed to download image' };
    }
  }, []);

  const handleCopyPrompt = useCallback(async (imageUrl, id) => {
    setButtonStates(prev => ({ ...prev, [id]: { copy: 'loading' } }));
    const result = await copyPromptToClipboard(imageUrl);
    if (result.success) {
      setButtonStates(prev => ({ ...prev, [id]: { copy: 'success' } }));
      setTimeout(() => {
        setButtonStates(prev => ({ ...prev, [id]: { copy: 'default' } }));
      }, 2000);
      return { success: true, message: 'Prompt copied to clipboard' };
    } else {
      setButtonStates(prev => ({ ...prev, [id]: { copy: 'default' } }));
      return { success: false, message: 'Failed to copy prompt' };
    }
  }, []);

  const handleCopyMarkdownLink = useCallback(async (imageUrl, id, imageTitle) => {
    setButtonStates(prev => ({ ...prev, [id]: { copyLink: 'loading' } }));
    const result = await copyMarkdownLinkToClipboard(imageUrl, imageTitle);
    if (result.success) {
      setButtonStates(prev => ({ ...prev, [id]: { copyLink: 'success' } }));
      setTimeout(() => {
        setButtonStates(prev => ({ ...prev, [id]: { copyLink: 'default' } }));
      }, 2000);
      return { success: true, message: 'Markdown link copied to clipboard' };
    } else {
      setButtonStates(prev => ({ ...prev, [id]: { copyLink: 'default' } }));
      return { success: false, message: 'Failed to copy markdown link' };
    }
  }, []);

  const handleReport = useCallback(async (imageId) => {
    setButtonStates(prev => ({ ...prev, [imageId]: { report: 'loading' } }));
    try {
      const result = await reportImage(imageId);
      if (result.success) {
        setButtonStates(prev => ({ ...prev, [imageId]: { report: 'success' } }));
        setTimeout(() => {
          setButtonStates(prev => ({ ...prev, [imageId]: { report: 'default' } }));
        }, 2000);
        return { success: true, message: 'Image reported successfully' };
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      setButtonStates(prev => ({ ...prev, [imageId]: { report: 'default' } }));
      setError('Failed to report image: ' + error.message);
      return { success: false, message: 'Failed to report image' };
    }
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return {
    buttonStates,
    error,
    handleDownload,
    handleCopyPrompt,
    handleCopyMarkdownLink,
    handleReport,
    clearError,
    setError
  };
};

export default useImageHandlers;