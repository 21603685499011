import React from 'react';
import ToolContent from '../ToolContent';

const DietPlanner = () => {
  const formFields = [
    { name: 'age', type: 'number', placeholder: 'Age:', required: true },
    {
      name: 'gender',
      type: 'select',
      placeholder: 'Select Gender:',
      options: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'other', label: 'Other' },
      ],
      required: true
    },
    { name: 'height', type: 'number', placeholder: 'Height (cm):', required: true },
    { name: 'weight', type: 'number', placeholder: 'Weight (kg):', required: true },
    {
      name: 'bodyType',
      type: 'select',
      placeholder: 'Select Body Type:',
      options: [
        { value: 'ectomorph', label: 'Ectomorph' },
        { value: 'mesomorph', label: 'Mesomorph' },
        { value: 'endomorph', label: 'Endomorph' },
      ]
    },
    {
      name: 'goal',
      type: 'select',
      placeholder: 'Select Goal:',
      options: [
        { value: 'bulking', label: 'Bulking' },
        { value: 'weight_loss', label: 'Weight Loss' },
        { value: 'muscle_gain', label: 'Muscle Gain' },
        { value: 'lean_mass', label: 'Lean Mass' },
      ],
      required: true
    },
    { name: 'targetWeight', type: 'number', placeholder: 'Target Weight (kg):' },
    { name: 'timeFrame', type: 'number', placeholder: 'Time Frame (weeks):' },
    {
      name: 'dailyActivity',
      type: 'select',
      placeholder: 'Select Daily Activity Level:',
      options: [
        { value: 'sedentary', label: 'Sedentary' },
        { value: 'lightly_active', label: 'Lightly Active' },
        { value: 'moderately_active', label: 'Moderately Active' },
        { value: 'very_active', label: 'Very Active' },
      ]
    },
    { name: 'exerciseRoutine', type: 'textarea', placeholder: 'Exercise Routine (describe):' },
    {
      name: 'dietType',
      type: 'select',
      placeholder: 'Select Diet Type:',
      options: [
        { value: 'omnivore', label: 'None' },
        { value: 'vegetarian', label: 'Vegetarian' },
        { value: 'vegan', label: 'Vegan' },
        { value: 'gluten_free', label: 'Gluten-Free' },
      ]
    },
    { name: 'foodAllergies', type: 'text', placeholder: 'Food Allergies (if any):' },
    { name: 'foodPreferences', type: 'textarea', placeholder: 'Food Preferences (likes/dislikes):' },
    { name: 'specialDietRequirements', type: 'text', placeholder: 'Special Diet Requirements:' },
    { name: 'medicalConditions', type: 'textarea', placeholder: 'Medical Conditions (if any):' },
    { name: 'currentIntake', type: 'number', placeholder: 'Typical Daily Intake (calories):' },
    { name: 'mealFrequency', type: 'number', placeholder: 'Meal Frequency (number of meals/snacks per day):' },
    { name: 'currentDiet', type: 'textarea', placeholder: 'Current Diet Description:' },
    { name: 'sleepPatterns', type: 'text', placeholder: 'Sleep Pattern:' },
    {
      name: 'stressLevels',
      type: 'select',
      placeholder: 'Select Stress Levels:',
      options: [
        { value: 'no-stress', label: 'No Stress' },
        { value: 'low', label: 'Low' },
        { value: 'medium', label: 'Medium' },
        { value: 'high', label: 'High' },
      ]
    },
    { name: 'hydration', type: 'number', placeholder: 'Daily Hydration (liters of water):' },
    { name: 'currentSupplements', type: 'text', placeholder: 'Current Supplements:' },
    { name: 'interestSupplements', type: 'text', placeholder: 'Interest in New Supplements:' },
    { name: 'location', type: 'text', placeholder: 'Location:', required: true },
    { name: 'localIngredients', type: 'textarea', placeholder: 'Locally Available Foods:' },
    {
      name: 'homeMadeVsSupplements',
      type: 'select',
      placeholder: 'Select Preference:',
      options: [
        { value: 'homemade', label: 'Homemade Meals Only' },
        { value: 'supplements', label: 'Supplements Preferred' },
        { value: 'both', label: 'Both Homemade and Supplements' },
      ]
    },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_DIET}
      toolTitle="Diet Planner"
      imageName = "diet_planned"
    />
  );
};

export default DietPlanner;