import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Download, Clipboard, Loader, Check, Trash2, EyeOff, Eye, Image, ImageOff, Link as LinkIcon, Flag, Heart } from 'lucide-react';
import LazyLoad from 'react-lazyload';
import '../css/ImageCard.css';

const ImageCard = React.memo(({
    image,
    isAdmin,
    onRemoveReportedStatus,
    onDeleteImage,
    index,
    selectedImageIndex,
    handleLike,
    copyPromptToClipboard,
    handleDownload,
    handleDelete,
    handleCopyMarkdownLink,
    handleReport,
    buttonStates,
    likeInProgress,
    isOwnProfile,
    isLikedImage,
    hideUserInfo,
    isMainImage,
    isToolGenerated,
    isShowingInContent,
    isOwnContentImages,
}) => {
    const navigate = useNavigate();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [profileImageLoaded, setProfileImageLoaded] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [is_liked, setIsLiked] = useState(image.is_liked);
    const [isLandscape, setIsLandscape] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [isBlurred, setIsBlurred] = useState(image.isAdult);
    const [showHeartAnimation, setShowHeartAnimation] = useState(false);
    const imageRef = useRef(null);
    const clickTimeoutRef = useRef(null);
    const clickCountRef = useRef(0);

    useEffect(() => {
        setIsLiked(image.is_liked);
    }, [image.is_liked]);

    useEffect(() => {
        setIsBlurred(image.isAdult);
    }, [image.isAdult]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCardClick = useCallback((e) => {
        e.preventDefault();
        clickCountRef.current += 1;

        if (clickTimeoutRef.current) {
            clearTimeout(clickTimeoutRef.current);
        }

        clickTimeoutRef.current = setTimeout(() => {
            if (clickCountRef.current === 1) {
                // Single click
                navigate(`/image/${image.id}`, { state: { image } });
            } else if (clickCountRef.current === 2) {
                // Double click
                if (!is_liked && !likeInProgress[image.id]) {
                    handleLike(image.id, index);
                    setIsLiked(true);
                    setShowHeartAnimation(true);
                    setTimeout(() => setShowHeartAnimation(false), 1000);
                }
            }
            clickCountRef.current = 0;
        }, 300);
    }, [navigate, image, handleLike, index, is_liked, likeInProgress]);

    const handleLikeClick = useCallback((e) => {
        e.stopPropagation();
        handleLike(image.id, index);
        setIsLiked(!is_liked);
        if (!is_liked) {
            setShowHeartAnimation(true);
            setTimeout(() => setShowHeartAnimation(false), 1000);
        }
    }, [handleLike, image.id, index, is_liked]);

    const handleCopyClick = useCallback((e) => {
        e.stopPropagation();
        copyPromptToClipboard(image.imageUrl, image.id);
    }, [copyPromptToClipboard, image.imageUrl, image.id]);

    const handleCopyMarkdownLinkClick = useCallback((e) => {
        e.stopPropagation();
        handleCopyMarkdownLink(image.imageUrl, image.id, image.title);
    }, [handleCopyMarkdownLink, image.imageUrl, image.id, image.title]);

    const handleDownloadClick = useCallback((e) => {
        e.stopPropagation();
        handleDownload(image.imageUrl, image.id);
    }, [handleDownload, image.imageUrl, image.id]);

    const handleDeleteClick = useCallback((e) => {
        e.stopPropagation();
        handleDelete(image.id, index);
    }, [handleDelete, image.id, index]);

    const handleImageLoad = useCallback(() => {
        setImageLoaded(true);
        if (imageRef.current) {
            setIsLandscape(imageRef.current.width > imageRef.current.height);
        }
    }, []);

    const handleReportClick = useCallback((e) => {
        e.stopPropagation();
        handleReport(image.id);
    }, [handleReport, image.id]);

    const handleProfileImageLoad = useCallback(() => {
        setProfileImageLoaded(true);
    }, []);

    const handleToggleHide = useCallback((e) => {
        e.stopPropagation();
        setIsHidden(!isHidden);
    }, [isHidden]);

    const handleToggleBlur = useCallback((e) => {
        e.stopPropagation();
        setIsBlurred(!isBlurred);
    }, [isBlurred]);

    return (
        <LazyLoad height={300} offset={100} once>
            <div
                className={`ic-image-card ${selectedImageIndex === index ? 'ic-selected' : ''} ${isMainImage ? 'ic-main-image' : ''} ${isToolGenerated ? 'ic-tool-image' : ''}`}
                onClick={!isToolGenerated ? handleCardClick : undefined}
            >
                <div className="ic-image-wrapper">
                    {!isHidden ? (
                        <img
                            ref={imageRef}
                            src={image.imageUrl}
                            alt={image.title || `Generated by ${image.username}`}
                            className={`ic-image ${imageLoaded ? 'ic-loaded' : ''} ${isBlurred ? 'ic-blurred' : ''} ${isMainImage ? 'ic-main-image-img' : ''}`}
                            onLoad={handleImageLoad}
                        />
                    ) : (
                        <div className="ic-hidden-image-placeholder">Image Hidden</div>
                    )}
                    {!imageLoaded && !isHidden && <div className="ic-image-placeholder" />}
                    <div className={`ic-heart-animation ${showHeartAnimation ? 'active' : ''}`}>
                        <Heart size={70} fill="yellow" />
                    </div>
                    <div className={`ic-image-overlay ${isMobile && selectedImageIndex === index ? 'ic-hidden' : ''}`}>
                        <div className="ic-image-details">
                            {!hideUserInfo && !isOwnProfile && (
                                <div className="ic-user-info">
                                    {image.userProfileImage && (
                                        <Link to={`/profile/${image.username}`} className="ic-user-link" onClick={(e) => e.stopPropagation()}>
                                            <div className={`ic-user-avatar-container ${image.badges?.social_ambassador ? 'ic-social-ambassador' : ''}`}>
                                                <img
                                                    src={image.userProfileImage}
                                                    alt={image.username}
                                                    className={`ic-user-avatar ${profileImageLoaded ? 'ic-loaded' : ''}`}
                                                    onLoad={handleProfileImageLoad}
                                                />
                                                {image.badges?.social_ambassador && (
                                                    <span className="ic-badge-icon" title="social ambassador badge">
                                                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                                                            <path d="m15,12c0,1.302-.839,2.402-2,2.816v-3.816h-2v3.816c-1.161-.414-2-1.514-2-2.816,0-1.708,1.819-3.67,3-4.708,1.181,1.038,3,3,3,4.708Zm7.08,4.175c.122,1.592-.451,3.167-1.595,4.31-1.144,1.143-2.711,1.718-4.31,1.595-1.039,1.212-2.558,1.92-4.175,1.92s-3.136-.708-4.175-1.92c-1.595.12-3.166-.452-4.31-1.595-1.144-1.144-1.717-2.718-1.595-4.31-1.212-1.039-1.92-2.558-1.92-4.175s.708-3.136,1.92-4.175c-.122-1.592.451-3.167,1.595-4.31,1.144-1.143,2.717-1.717,4.31-1.595,1.039-1.212,2.558-1.92,4.175-1.92s3.136.708,4.175,1.92c1.595-.121,3.167.452,4.31,1.595,1.144,1.144,1.717,2.718,1.595,4.31,1.212,1.039,1.92,2.558,1.92,4.175s-.708,3.136-1.92,4.175Zm-5.08-4.175c0-3.402-3.95-6.462-4.4-6.8l-.6-.45-.6.45c-.45.337-4.4,3.398-4.4,6.8,0,2.414,1.721,4.434,4,4.899v2.101h2v-2.101c2.279-.465,4-2.484,4-4.899Z" />
                                                        </svg>
                                                    </span>
                                                )}
                                            </div>
                                        </Link>
                                    )}
                                    {!profileImageLoaded && <div className="ic-avatar-placeholder" />}
                                    <Link to={`/profile/${image.username}`} className="ic-user-link" onClick={(e) => e.stopPropagation()}>
                                        {image.username}
                                    </Link>
                                </div>
                            )}
                            {isBlurred && image.isAdult && (
                                <div className="ic-image-reason">{image.reason}</div>
                            )}
                            {!isShowingInContent && !isBlurred && (
                                <div className="ic-image-title">{image.title}</div>
                            )}
                            <div className={`ic-image-buttons ${isLandscape ? 'ic-landscape' : ''}`}>
                                {isOwnContentImages && (
                                    <button
                                        onClick={handleCopyMarkdownLinkClick}
                                        className="ic-action-button"
                                        title="Copy Markdown Link"
                                        disabled={buttonStates[image.id]?.copyLink === 'loading'}
                                    >
                                        {buttonStates[image.id]?.copyLink === 'loading' ? (
                                            <Loader size={22} className="ic-action-icon ic-animate-spin" />
                                        ) : buttonStates[image.id]?.copyLink === 'success' ? (
                                            <Check size={22} className="ic-action-icon" />
                                        ) : (
                                            <LinkIcon size={22} className="ic-action-icon" />
                                        )}
                                    </button>
                                )}
                                {!isToolGenerated && (
                                    <button
                                        className={`ic-action-button ic-image-likes ${is_liked ? 'ic-liked' : ''}`}
                                        onClick={handleLikeClick}
                                        disabled={likeInProgress[image.id]}
                                    >
                                        <Heart
                                            size={22}
                                            fill={is_liked ? 'yellow' : 'none'}
                                            color={is_liked ? 'yellow' : '#fff'}
                                        />
                                        {image.likeCount}
                                    </button>
                                )}
                                <button
                                    onClick={handleCopyClick}
                                    className="ic-action-button"
                                    title="Copy Prompt"
                                    disabled={buttonStates[image.id]?.copy === 'loading'}
                                >
                                    {buttonStates[image.id]?.copy === 'loading' ? (
                                        <Loader size={22} className="ic-action-icon ic-animate-spin" />
                                    ) : buttonStates[image.id]?.copy === 'success' ? (
                                        <Check size={22} className="ic-action-icon" />
                                    ) : (
                                        <Clipboard size={22} className="ic-action-icon" />
                                    )}
                                </button>
                                <button
                                    onClick={handleDownloadClick}
                                    className="ic-action-button"
                                    title="Download"
                                    disabled={buttonStates[image.id]?.download === 'loading'}
                                >
                                    {buttonStates[image.id]?.download === 'loading' ? (
                                        <Loader size={22} className="ic-action-icon ic-animate-spin" />
                                    ) : buttonStates[image.id]?.download === 'success' ? (
                                        <Check size={22} className="ic-action-icon" />
                                    ) : (
                                        <Download size={22} className="ic-action-icon" />
                                    )}
                                </button>
                                {!isShowingInContent && !isOwnProfile && (
                                    <button
                                        className='ic-action-button ic-hide'
                                        onClick={handleToggleHide}
                                        title={isHidden ? "Show Image" : "Hide Image"}
                                    >
                                        {isHidden ? (
                                            <Eye size={22} className='ic-action-icon ic-info' />
                                        ) : (
                                            <EyeOff size={22} className='ic-action-icon ic-info' />
                                        )}
                                    </button>
                                )}
                                {image.isAdult && (
                                    <button
                                        className='ic-action-button ic-blur'
                                        onClick={handleToggleBlur}
                                        title={isBlurred ? "Unblur Image" : "Blur Image"}
                                    >
                                        {isBlurred ? (
                                            <Image size={22} className='ic-action-icon ic-info' />
                                        ) : (
                                            <ImageOff size={22} className='ic-action-icon ic-info' />
                                        )}
                                    </button>
                                )}
                                {(isOwnProfile || isOwnContentImages) && !isLikedImage && (
                                    <button
                                        onClick={handleDeleteClick}
                                        className="ic-action-button ic-delete"
                                        title="Delete"
                                    >
                                        <Trash2 size={22} className="ic-action-icon ic-delete" />
                                    </button>
                                )}
                                {!isToolGenerated && !isOwnProfile && !isAdmin &&(
                                    <button
                                        onClick={handleReportClick}
                                        className="ic-action-button ic-report"
                                        title="Report Image"
                                        disabled={buttonStates[image.id]?.report === 'loading'}
                                    >
                                        {buttonStates[image.id]?.report === 'loading' ? (
                                            <Loader size={22} className="ic-action-icon ic-animate-spin" />
                                        ) : buttonStates[image.id]?.report === 'success' ? (
                                            <Check size={22} className="ic-action-icon" />
                                        ) : (
                                            <Flag size={22} className="ic-action-icon" />
                                        )}
                                    </button>
                                )}
                                {isAdmin && (
                                    <>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onRemoveReportedStatus();
                                            }}
                                            className="ic-action-button"
                                            title="Remove Reported Status"
                                        >
                                            <Flag size={22} className="ic-action-icon" />
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onDeleteImage();
                                            }}
                                            className="ic-action-button ic-delete"
                                            title="Delete Image"
                                        >
                                            <Trash2 size={22} className="ic-action-icon ic-delete" />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LazyLoad>
    );
});

export default ImageCard;