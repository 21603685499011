import React from 'react';
import ToolContent from '../ToolContent';

const GiftSuggestionGenerator = () => {
  const formFields = [
    {
      name: 'occasion',
      type: 'select',
      placeholder: 'Select Occasion:',
      options: [
        { value: 'birthday', label: 'Birthday' },
        { value: 'anniversary', label: 'Anniversary' },
        { value: 'holiday', label: 'Holiday' },
        { value: 'wedding', label: 'Wedding' },
        { value: 'graduation', label: 'Graduation' },
      ],
      required: true
    },
    { name: 'recipientAge', type: 'text', placeholder: "Recipient's Age:", required: true },
    {
      name: 'recipientGender',
      type: 'select',
      placeholder: "Select Recipient's Gender:",
      options: [
        { value: '', label: 'Any' },
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
      ]
    },
    { name: 'interests', type: 'textarea', placeholder: "Recipient's Interests/Hobbies:" },
    { name: 'profession', type: 'text', placeholder: "Recipient's Profession:" },
    { name: 'budget', type: 'text', placeholder: 'Budget Range ($):' },
    { name: 'giftPreferences', type: 'textarea', placeholder: 'Gift Preferences (e.g., tech gadgets, handmade items):' },
    { name: 'existingGifts', type: 'textarea', placeholder: 'Existing Gifts or Dislikes:' },
    { name: 'relationship', type: 'text', placeholder: 'Relationship to Recipient:' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_GIFT_SUGGESTIONS}
      toolTitle="Gift Suggestion Generator"
      imageName = "gift_suggestion"
    />
  );
};

export default GiftSuggestionGenerator;