import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, Search, User, BrainCircuit } from 'lucide-react';
import '../css/Header.css'
import { FaInfo, FaQuestion } from 'react-icons/fa';
import HeaderMessage from './HeaderMessage';

const Header = () => {
  const isLoggedIn = !!localStorage.getItem('token');
  const location = useLocation();

  useEffect(() => {
    const setViewHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    const updateNavHeight = () => {
      const navHeight = window.innerHeight - window.visualViewport.height + 60;
      document.documentElement.style.setProperty('--mobile-nav-height', `${navHeight}px`);
    };

    setViewHeight();
    updateNavHeight();

    window.addEventListener('resize', setViewHeight);
    window.visualViewport.addEventListener('resize', updateNavHeight);

    return () => {
      window.removeEventListener('resize', setViewHeight);
      window.visualViewport.removeEventListener('resize', updateNavHeight);
    };
  }, []);

  const NavItem = ({ to, icon: Icon, label }) => {
    const isActive = location.pathname === to;
    return (
      <Link
        to={to}
        className={`header-nav-item ${isActive ? 'active' : ''}`}
      >
        <Icon size={24} />
        <span className="header-nav-label">{label}</span>
      </Link>
    );
  };

  return (
    <>
      {!isLoggedIn && (
        <div className='header-contact-icons'>
          <Link to="/" className="header-contact-us" title="Contact Us">
            <FaInfo size={20} />
          </Link>
          <Link to="/blog" className="header-contact-us" title="Questions">
            <FaQuestion size={20} />
          </Link>
        </div>
      )}
      <nav className="header-header-nav">
        <div className="header-nav-container">
          <div className="header-nav-items">
            <NavItem to="/home" icon={Home} label="Home" />
            <NavItem to="/explore" icon={Search} label="Explore" />
            <NavItem to="/create" icon={BrainCircuit} label="Create" />
            {isLoggedIn ? (
              <NavItem to="/profile" icon={User} label="Profile" />
            ) : (
              <NavItem to="/login" icon={User} label="Login" />
            )}
          </div>
        </div>
      </nav>
      <div className="header-header-spacer"></div>
      {isLoggedIn && <HeaderMessage isLoggedIn={isLoggedIn} />}
    </>
  );
}

export default Header;