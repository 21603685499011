import React, { useState, useEffect } from 'react';
import { FaInstagram, FaTimes, FaInfoCircle, FaBullhorn, FaTrophy, FaUsers } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import '../css/HeaderMessage.css';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';

const HeaderMessage = ({ isLoggedIn }) => {
    const [notifications, setNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [closingNotification, setClosingNotification] = useState(null);

    useEffect(() => {
        let isMounted = true;
        let timeoutId;

        const fetchNotifications = async () => {
            try {
                const response = await authenticatedApiCall('/current-notifications', 'GET');
                if (isMounted && Array.isArray(response)) {
                    setNotifications(response);
                    setTimeout(() => {
                        setShowNotifications(response.length > 0);
                    }, 10000);
                } else {
                    setNotifications([]);
                    setShowNotifications(false);
                }
            } catch (error) {
                console.error('Error fetching notifications:', error);
            } finally {
                if (isMounted) {
                    timeoutId = setTimeout(fetchNotifications, 1800000);
                }
            }
        };

        const updateLastActive = async () => {
            try {
                await authenticatedApiCall('/update-last-active', 'POST');
            } catch (error) {
                console.error('Error updating last active:', error);
            } finally {
                if (isMounted) {
                    timeoutId = setTimeout(updateLastActive, 1850000);
                }
            }
        };

        if (isLoggedIn) {
            fetchNotifications();
            updateLastActive();
        }

        return () => {
            isMounted = false;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isLoggedIn]);

    const handleClose = (notificationId) => {
        if (closingNotification) return; // Prevent rapid clicking

        setClosingNotification(notificationId);

        setTimeout(() => {
            setNotifications(prevNotifications =>
                prevNotifications.filter(notification => notification.id !== notificationId)
            );

            if (notifications.length === 1) {
                setShowNotifications(false);
            }

            setClosingNotification(null);

            authenticatedApiCall(`/notifications/${notificationId}/update`, 'POST', { action: 'close' })
                .catch(error => {
                    console.error('Error marking notification as closed:', error);
                });
        }, 300); // Match this with the animation duration
    };

    const handleActionClick = (notificationId, actionUrl) => {
        authenticatedApiCall(`/notifications/${notificationId}/update`, 'POST', { action: 'open' })
            .catch(error => {
                console.error('Error marking notification as opened:', error);
            });
        window.open(actionUrl, '_blank');
    };

    const getIcon = (type) => {
        switch (type) {
            case 'instagram': return FaInstagram;
            case 'info': return FaInfoCircle;
            case 'promotion': return FaBullhorn;
            case 'contest': return FaTrophy;
            case 'community': return FaUsers;
            default: return FaInfoCircle;
        }
    };

    if (!isLoggedIn || !showNotifications || notifications.length === 0) return null;

    return (
        <AnimatePresence>
            {showNotifications && (
                <motion.div
                    className="header-message-stack"
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.3 }}
                >
                    {notifications.map((notification, index) => {
                        const Icon = getIcon(notification.type);
                        return (
                            <motion.div
                                key={notification.id}
                                className={`header-message ${notification.type}`}
                                initial={{ opacity: 0, y: -20, zIndex: notifications.length - index }}
                                animate={{
                                    opacity: 1,
                                    y: 0,
                                    scale: closingNotification === notification.id ? 0.9 : 1,
                                }}
                                exit={{ opacity: 0, scale: 0.9, transition: { duration: 0.2 } }}
                                transition={{ duration: 0.2 }}
                                style={{
                                    position: 'absolute',
                                    top: `${index * 10}px`,
                                    right: 0,
                                    left: 0,
                                }}
                            >
                                <button
                                    className="hm-close-button"
                                    onClick={() => handleClose(notification.id)}
                                    disabled={closingNotification !== null}
                                >
                                    <FaTimes />
                                </button>
                                <div className="hm-icon">
                                    <Icon />
                                </div>
                                <div className="hm-content">
                                    <h3 className="hm-title">{notification.title}</h3>
                                    <p className="hm-description">{notification.message}</p>
                                </div>
                                {notification.action && (
                                    <button
                                        className={`hm-button ${notification.type === 'instagram' ? 'hm-instagram-button' : ''}`}
                                        onClick={() => handleActionClick(notification.id, notification.action)}
                                    >
                                        {notification.type === 'instagram' ? 'Open Instagram' : 'Learn More'}
                                    </button>
                                )}
                            </motion.div>
                        );
                    })}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default HeaderMessage;