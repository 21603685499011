import React from 'react';
import { FaInstagram, FaFacebook, FaTwitter, FaGlobe } from 'react-icons/fa';
import '../../css/SocialLinks.css';

const getSocialIcon = (url) => {
    if (url.includes('instagram.com')) return <FaInstagram size={20} />;
    if (url.includes('facebook.com')) return <FaFacebook size={20} />;
    if (url.includes('twitter.com')) return <FaTwitter size={20} />;
    return <FaGlobe size={20} />;
};

const SocialLinks = ({ socialLinks, isEditing, onSocialLinksChange }) => {
    if (isEditing) {
        return (
            <div className="profile-social-links-edit">
                <input
                    type="text"
                    value={socialLinks.instagram || ''}
                    placeholder='Instagram'
                    onChange={(e) => onSocialLinksChange({ ...socialLinks, instagram: e.target.value })}
                />
                <input
                    type="text"
                    value={socialLinks.facebook || ''}
                    placeholder='Facebook'
                    onChange={(e) => onSocialLinksChange({ ...socialLinks, facebook: e.target.value })}
                />
                <input
                    type="text"
                    value={socialLinks.twitter || ''}
                    placeholder='X (twitter)'
                    onChange={(e) => onSocialLinksChange({ ...socialLinks, twitter: e.target.value })}
                />
            </div>
        );
    }

    return (
        <div className="profile-social-links">
            {Object.entries(socialLinks || {}).map(([platform, link]) => (
                link && (
                    <a key={platform} href={link} target="_blank" rel="noopener noreferrer" className="social-link">
                        {getSocialIcon(link)}
                    </a>
                )
            ))}
        </div>
    );
};

export default SocialLinks;