import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import '../../css/GoogleSignIn.css';
import { isImageGenerationLimited } from '../../utils/cookies';

const API_URL = process.env.REACT_APP_API;
// const API_URL = "http://localhost:5000";

const GoogleSignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showImageGenerationReason, setShowImageGenerationReason] = useState(false);

  useEffect(() => {
    setShowImageGenerationReason(isImageGenerationLimited() && location.state?.from === '/generate-image');
  }, [location]);

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = initializeGoogleLogin;
    };
    loadGoogleScript();
    // eslint-disable-next-line
  }, []);

  const initializeGoogleLogin = () => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_CLIENT_ID,
      callback: handleGoogleLogin
    });
    window.google.accounts.id.renderButton(
      document.getElementById('google-login-button'),
      { theme: 'filled_blue', size: 'large', shape: 'pill', text: 'continue_with' }
    );
  };

  const handleGoogleLogin = async (response) => {
    try {
      const res = await axios.post(`${API_URL}/google-login`, {
        token: response.credential
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-Key': process.env.REACT_APP_KEY,
        }
      });
      const { token, user } = res.data;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      navigate(location.state?.from || '/');
    } catch (error) {
      console.error('Error during Google login:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
    }
  };

  return (
    <div className="gs-container margin">
      <div className="gs-content">
        <div className="gs-logo-container">
          <img src="/images/fav-da.png" alt="Logo" className="gs-logo" />
        </div>
        <h1 className="gs-title">Welcome to AI Tools</h1>
        {!showImageGenerationReason && (
          <p className="gs-subtitle">Sign in with Google to access all features</p>
        )}
        {showImageGenerationReason && (
          <p className="gs-subtitle">
            😊 Enjoying the image creation? Simply log in to keep generating for free or try our other tools!
          </p>
        )}
        <div className="gs-benefits">
          <h2>Why Create an Account?</h2>
          <ul>
            <li><AiOutlineCheckCircle /> Personalized experience</li>
            <li><AiOutlineCheckCircle /> Unlimited generations</li>
            <li><AiOutlineCheckCircle /> Save your art</li>
            <li><AiOutlineCheckCircle /> Instant downloads</li>
            <li><AiOutlineCheckCircle /> Follow artists</li>
          </ul>
        </div>
        <div className="gs-signin-button" id="google-login-button"></div>
        <p className="gs-terms">By signing in, you agree to our <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link></p>
      </div>
    </div>
  );
};

export default GoogleSignIn;