import React from 'react';
import ToolContent from '../ToolContent';

const StoryGenerator = () => {
  const formFields = [
    { name: 'title', type: 'text', placeholder: 'Story Title:', required: true },
    {
      name: 'genre',
      type: 'select',
      placeholder: 'Select Genre:',
      options: [
        { value: 'Fantasy', label: 'Fantasy' },
        { value: 'Sci-Fi', label: 'Sci-Fi' },
        { value: 'Romance', label: 'Romance' },
        { value: 'Mystery', label: 'Mystery' },
        { value: 'Adventure', label: 'Adventure' },
        { value: 'Comedy', label: 'Comedy' },
        { value: 'Horror', label: 'Horror' },
      ],
    },
    { name: 'mainCharacter', type: 'text', placeholder: 'Main Character Name:' },
    { name: 'storyPrompt', type: 'textarea', placeholder: 'Story Prompt or Initial Situation:' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_STORIES}
      toolTitle="Story Generator"
      imageName="story_image"
    />
  );
};

export default StoryGenerator;