import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import ImageCard from './ImageCard';
import Masonry from 'react-masonry-css';
import { ArrowLeft } from 'lucide-react';
import '../css/ImageDetailView.css';
import useImageHandlers from '../hooks/image/useImageHandlers';
import useLikeImage from '../hooks/image/useLikeImage';

const breakpointColumnsObj = {
  default: 4,
  1200: 4,
  1024: 3,
  768: 2,
  400: 2,
  350: 1
};

const ImageDetailView = () => {
  const { imageId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  // Get the main image data from the location state
  const initialMainImage = location.state?.image;

  const {
    buttonStates,
    error,
    handleDownload,
    handleCopyPrompt,
    handleReport,
    setError
  } = useImageHandlers();

  const {
    images,
    setImages,
    handleLike,
    likeInProgress,
    error: likeError
  } = useLikeImage([initialMainImage]);

  // Use useMemo to derive the current mainImage and relatedImages from the images array
  const { mainImage, relatedImages } = useMemo(() => {
    const main = images.find(img => img.id === initialMainImage.id) || initialMainImage;
    const related = images.filter(img => img.id !== initialMainImage.id);
    return { mainImage: main, relatedImages: related };
  }, [images, initialMainImage]);

  const fetchRelatedImages = useCallback(async () => {
    setLoading(true);
    try {
      const relatedImagesResponse = await authenticatedApiCall(`/get-related-images/${imageId}`, 'GET');
      setImages(prevImages => {
        const newImages = [...prevImages];
        relatedImagesResponse.forEach(newImg => {
          if (!newImages.some(img => img.id === newImg.id)) {
            newImages.push(newImg);
          }
        });
        return newImages;
      });
    } catch (err) {
      console.error('Error fetching related images:', err);
      setError('Error fetching related images: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [imageId, setError, setImages]);

  useEffect(() => {
    if (initialMainImage) {
      fetchRelatedImages();
    } else {
      navigate(-1);
    }
  }, [fetchRelatedImages, initialMainImage, navigate]);

  useEffect(() => {
    if (likeError) {
      setError(likeError);
    }
  }, [likeError, setError]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleImageClick = useCallback((index) => {
    if (window.innerWidth <= 768) {
      setSelectedImageIndex(prevIndex => prevIndex === index ? null : index);
    }
  }, []);

  const handleUsernameClick = (username) => {
    navigate(`/profile/${username}`);
  };

  // New function for rendering skeleton loading
  const renderSkeletonLoading = () => {
    return Array.from({ length: 8 }).map((_, index) => (
      <div key={`skeleton-${index}`} className="idv-skeleton-item">
        <div className="idv-skeleton-image"></div>
        <div className="idv-skeleton-text"></div>
      </div>
    ));
  };

  if (error) return <div className="idv-error">{error}</div>;
  if (!mainImage) return null;
  return (
    <div className="idv-container margin">
      <button className="idv-back-button" onClick={handleBack}>
        <ArrowLeft size={24} />
        Back
      </button>
      <div className="idv-main-image-container">
        <ImageCard
          image={mainImage}
          index={0}
          selectedImageIndex={selectedImageIndex}
          handleImageClick={handleImageClick}
          handleLike={handleLike}
          copyPromptToClipboard={handleCopyPrompt}
          handleDownload={(url, id) => handleDownload(url, id, mainImage.title)}
          handleReport={handleReport}
          buttonStates={buttonStates}
          likeInProgress={likeInProgress}
          handleUsernameClick={handleUsernameClick}
          isMainImage={true}
        />
      </div>
      <h3 className="idv-related-images-title">Related Images</h3>
      {loading ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {renderSkeletonLoading()}
        </Masonry>
      ) : relatedImages.length > 0 ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {relatedImages.map((image, index) => (
            <ImageCard
              key={`${image.id}-${index}`}
              image={image}
              index={index + 1}
              selectedImageIndex={selectedImageIndex}
              handleImageClick={handleImageClick}
              handleLike={handleLike}
              copyPromptToClipboard={handleCopyPrompt}
              handleDownload={(url, id) => handleDownload(url, id, image.title)}
              handleReport={handleReport}
              buttonStates={buttonStates}
              likeInProgress={likeInProgress}
              handleUsernameClick={handleUsernameClick}
            />
          ))}
        </Masonry>
      ) : (
        <p className="idv-no-related-images">No related images found</p>
      )}
    </div>
  );
};

export default ImageDetailView;