import React from 'react';
import '../css/PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="pp-container margin">
            <h1 className="pp-title">Privacy Policy</h1>
            <div className="pp-content">
                <section className="pp-section">
                    <h2 className="pp-section-title">1. Information We Collect</h2>
                    <p className="pp-text">We collect information you provide directly to us when using our services, including:</p>
                    <ul className="pp-list">
                        <li>Content you save using our AI tools</li>
                        <li>Your google account public information (name, email, profile picture)</li>
                        <li>Any other information you choose to provide.</li>
                        <li>Content you just create and don't save is not collected or saved anywhere by us</li>
                    </ul>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">2. How We Use Your Information</h2>
                    <p className="pp-text">We use the information we collect to:</p>
                    <ul className="pp-list">
                        <li>Provide, maintain, and improve our services</li>
                        <li>Respond to your comments, questions, and requests</li>
                        <li>Monitor and analyze trends, usage, and activities in connection with our services</li>
                    </ul>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">3. Sharing of Information</h2>
                    <p className="pp-text">We may share information as follows:</p>
                    <ul className="pp-list">
                        {/* <li>With other service providers who need access to such information to carry out work on our behalf (e.g., Groq and Pollinations)</li> */}
                        <li>We are not sharing any user info with other third party or service providers.</li>
                        <li>Unless we believe disclosure is necessary or appropriate to protect the rights, property, or safety of ai.devsaura.com, our users, or others</li>
                    </ul>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">4. Data Retention</h2>
                    <p className="pp-text">We store the information we collect for as long as it is necessary for the purpose(s) for which we originally collected it. We may retain certain information for legitimate business purposes or as required by law.</p>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">5. Security</h2>
                    <p className="pp-text">We take reasonable measures to help protect information about you from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.</p>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">6. Your Rights</h2>
                    <p className="pp-text">You may have the right to access, update, or delete your information. Please contact us if you wish to exercise these rights.</p>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">7. Changes to this Policy</h2>
                    <p className="pp-text">We may change this privacy policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy.</p>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">8. Contact Us</h2>
                    <p className="pp-text">If you have any questions about this privacy policy, please contact us at: <a href="mailto:tools@devsaura.com">tools@devsaura.com</a></p>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicy;