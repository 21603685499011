import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import '../css/Home.css';
import ImageCard from './ImageCard';
import useLikeImage from '../hooks/image/useLikeImage';
import useImageHandlers from '../hooks/image/useImageHandlers';
import Masonry from 'react-masonry-css';
import Flyer from './Flyer';
import { getRandomFlyer } from '../data/FlyerData';
import ContentViewer from './ContentViewer';

const breakpointColumnsObj = {
  default: 5,
  1200: 5,
  1024: 4,
  768: 3,
  576: 2,
  460: 1
};

const Home = () => {
  const [activeView, setActiveView] = useState('Images');
  const [feedImages, setFeedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef(null);
  const location = useLocation();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const adRef = useRef(null);
  const navigate = useNavigate();

  const {
    images,
    setImages: setLikeImages,
    handleLike,
    likeInProgress,
    error: likeError
  } = useLikeImage(feedImages);

  const {
    buttonStates,
    error,
    handleDownload,
    handleCopyPrompt,
    handleReport,
    setError
  } = useImageHandlers();

  const fetchImages = useCallback(async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response = await authenticatedApiCall(`/get-random-images?page=${page}&per_page=20`, 'GET');
      if (response.length > 0) {
        setFeedImages(prevImages => {
          const newImages = response.filter(newImg => !prevImages.some(existingImg => existingImg.id === newImg.id));
          return [...prevImages, ...newImages];
        });
        setPage(prevPage => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (err) {
      setError('Error fetching images: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, page, setError]);

  const resetImageState = useCallback(() => {
    setFeedImages([]);
    setPage(1);
    setHasMore(true);
    setLoading(false); // Set loading to false to allow immediate fetch
  }, []);

  const handleViewChange = useCallback((view) => {
    setActiveView(view);
    window.scrollTo({ top: 0 });
    if (view === 'Images') {
      resetImageState();
    }
  }, [resetImageState]);

  useEffect(() => {
    if (activeView === 'Images' && feedImages.length === 0 && !loading) {
      fetchImages();
    }
  }, [activeView, feedImages.length, fetchImages, loading]);

  const handleImageClick = useCallback((index) => {
    if (window.innerWidth <= 768) {
      setSelectedImageIndex(prevIndex => prevIndex === index ? null : index);
    }
  }, []);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && !loading && hasMore) {
      fetchImages();
    }
  }, [loading, hasMore, fetchImages]);

  useEffect(() => {
    setLikeImages(feedImages);
  }, [feedImages, setLikeImages]);

  useEffect(() => {
    if (likeError) {
      setError(likeError);
    }
  }, [likeError, setError]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.home-image-card')) {
        setSelectedImageIndex(null);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [location.pathname]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "300px",
      threshold: 0.1
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);

    return () => {
      // eslint-disable-next-line
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [handleObserver]);

  useEffect(() => {
    const loadAds = () => {
      try {
        if (typeof window !== "undefined") {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
      } catch (error) {
        console.error("Error loading ads:", error);
      }
    };

    if (adRef.current) {
      loadAds();
    }
  }, []);

  const renderFlyerOrPlaceholder = useCallback((index) => {
    // Show a Flyer every 20th item, starting from the 5th
    if ((index + 1) % 20 === 5) {
      return <Flyer key={`flyer-${index}`} data={getRandomFlyer()} />;
    }
    return null;
  }, []);

  const renderItems = useCallback(() => {
    const items = [];
    images.forEach((image, index) => {
      items.push(
        <ImageCard
          key={image.id}
          image={{
            ...image,
            is_liked: image.is_liked
          }}
          index={index}
          selectedImageIndex={selectedImageIndex}
          handleImageClick={handleImageClick}
          handleLike={handleLike}
          copyPromptToClipboard={handleCopyPrompt}
          handleDownload={(url, id) => handleDownload(url, id, image.title)}
          handleReport={handleReport}
          buttonStates={buttonStates}
          likeInProgress={likeInProgress}
        />
      );

      const flyer = renderFlyerOrPlaceholder(index);
      if (flyer) {
        items.push(flyer);
      }
    });
    return items;
  }, [images, selectedImageIndex, handleImageClick, handleLike, handleCopyPrompt, handleDownload, buttonStates, likeInProgress, renderFlyerOrPlaceholder, handleReport]);

  const SkeletonCard = () => (
    <div className="home-image-card home-skeleton">
      <div className="home-skeleton-image"></div>
      <div className="home-skeleton-text"></div>
      <div className="home-skeleton-text home-skeleton-text-short"></div>
    </div>
  );

  const renderContent = () => {
    if (activeView === 'Images') {
      return (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {loading && images.length === 0
            ? Array(20).fill().map((_, index) => <SkeletonCard key={index} />)
            : renderItems()}
          {loading && images.length > 0 && Array(10).fill().map((_, index) => <SkeletonCard key={`loading-${index}`} />)}
        </Masonry>
      );
    } else {
      return <ContentViewer />;
    }
  };
  const renderEndContent = () => {
    if (!loading && !hasMore) {
      return (
        <div className="home-end-content">
          <p className="home-no-more-images">You've reached the end of your feed</p>
          <div className="home-end-buttons">
            <button
              className="home-end-button explore"
              onClick={() => {
                handleViewChange('Other');
              }}
            >
              Explore Other Content
            </button>
            <button
              className="home-end-button create"
              onClick={() => navigate('/create')}
            >
              Create New
            </button>
          </div>
        </div>
      );
    }
    return null;
  };


  return (
    <div className="home-home-container margin margin-home">
      <div className="home-view-buttons">
        <button
          onClick={() => handleViewChange('Images')}
          className={`home-view-button ${activeView === 'Images' ? 'active' : ''}`}
        >
          Images
        </button>
        <button
          onClick={() => handleViewChange('Other')}
          className={`home-view-button ${activeView === 'Other' ? 'active' : ''}`}
        >
          Other
        </button>
      </div>

      {renderContent()}

      {activeView === 'Images' && (
        <>
          {renderEndContent()}
          {error && <p className="home-error-text">{error}</p>}
          {hasMore && <div ref={loader} />}
        </>
      )}
    </div>
  );
};

export default Home;