import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import ImageCard from './ImageCard';
import useImageHandlers from '../hooks/image/useImageHandlers';
import ContentEditor from './ContentEditor';
import '../css/ContentPreview.css';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';

const ContentPreview = ({ content, onClose, onEdit, onDelete, isYourContent, startEditing }) => {
  const [isEditing, setIsEditing] = useState(startEditing);
  const [currentContent, setCurrentContent] = useState(content);
  const {
    buttonStates,
    handleDownload,
    handleCopyPrompt,
    handleCopyMarkdownLink,
    // eslint-disable-next-line
    clearError,
  } = useImageHandlers();

  useEffect(() => {
    setIsEditing(startEditing);
    setCurrentContent(content);
  }, [startEditing, content]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = async (editedContent) => {
    await onEdit(currentContent._id.$oid, editedContent);
    setCurrentContent({ ...currentContent, content: editedContent });
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleDelete = async (imageUrl) => {
    try {
      const response = await authenticatedApiCall('/delete-content-image', 'POST', {
        content_id: currentContent._id.$oid,
        image_url: imageUrl
      });

      if (response.success) {
        const updatedImageUrls = currentContent.image_urls.filter(url => url !== imageUrl);
        setCurrentContent({ ...currentContent, image_urls: updatedImageUrls });
      } else {
        console.error('Failed to delete image:', response.message);
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const containsLinks = (text) => {
    // eslint-disable-next-line
    const linkRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    // eslint-disable-next-line
    const wwwRegex = /(^|[^\/])(www\.[a-z0-9\-]+(\.[a-z]{2,})([^\s]*)?)/gi;
    const mailtoRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/gi;
    // eslint-disable-next-line
    const internalLinkRegex = /\[([^\]]+)\]\((\/|https?:\/\/|www\.|[a-zA-Z0-9\-]+\.[a-zA-Z]{2,})([^)]+)?\)/g;
    const anchorLinkRegex = /#[a-zA-Z0-9_-]+/g;

    // Function to filter out harmless links
    const isHarmlessLink = (link) => link.includes('image.pollinations.ai');

    // Find all matches in the text for each regex
    const linkMatches = text.match(linkRegex) || [];
    const wwwMatches = text.match(wwwRegex) || [];
    const mailtoMatches = text.match(mailtoRegex) || [];
    const internalLinkMatches = text.match(internalLinkRegex) || [];
    const anchorLinkMatches = text.match(anchorLinkRegex) || [];

    // Filter out harmless links
    const filteredLinks = [...linkMatches, ...wwwMatches, ...internalLinkMatches].filter(
      (link) => !isHarmlessLink(link)
    );

    // Return true if any of the non-harmless links or other links are found
    return filteredLinks.length > 0 || mailtoMatches.length > 0 || anchorLinkMatches.length > 0;
  };



  return (
    <div className="cp-preview-overlay" onClick={onClose}>
      <div className="cp-preview-content" onClick={(e) => e.stopPropagation()}>
        <button className="cp-preview-close" onClick={onClose}>&times;</button>
        <div className="cp-preview-page cp-content">
          {isEditing ? (
            <>
              <div className="cp-warning">
                <p>Warning: Do not add any inappropriate or harmful content. <a href="/blog/other/content-instructions" target="_blank" rel="noopener noreferrer">Read content instructions</a></p>
              </div>
              <ContentEditor
                content={currentContent}
                onSave={handleSaveEdit}
                onCancel={handleCancelEdit}
              />
            </>
          ) : (
            <>
              {!isYourContent && containsLinks(currentContent.content) && (
                <div className="cp-caution">
                  <p>Caution: This content might contain links. Be careful when clicking any link, especially in content you don't own. <a href="/blog/other/content-instructions" target="_blank" rel="noopener noreferrer">Learn more about content safety</a></p>
                </div>
              )}
              <div className="cp-preview-text">
                <ReactMarkdown>{currentContent.content}</ReactMarkdown>
              </div>
              <p className="cp-preview-date">
                Created at: {new Date(currentContent.created_at.$date).toLocaleString()}
              </p>
              {isYourContent && (
                <div className="cp-preview-buttons">
                  <button className="cp-edit-button" onClick={handleEditClick}>Edit</button>
                </div>
              )}
            </>
          )}
        </div>
        <div className="cp-preview-spine"></div>
        <div className="cp-preview-page">
          <div className="cp-preview-images">
            {currentContent.image_urls.map((imageUrl, index) => (
              <ImageCard
                key={index}
                image={{
                  id: `preview-${index}`,
                  imageUrl: imageUrl,
                  title: `${currentContent.tool_name} Image ${index + 1}`,
                  username: currentContent.user_name || 'AI',
                  is_liked: false
                }}
                index={index}
                copyPromptToClipboard={handleCopyPrompt}
                handleCopyMarkdownLink={handleCopyMarkdownLink}
                handleDownload={(url, id) => handleDownload(url, id, `${currentContent.tool_name}-${index + 1}`)}
                buttonStates={buttonStates}
                hideUserInfo={true}
                isToolGenerated={true}
                isShowingInContent={true}
                isOwnContentImages={isYourContent}
                handleDelete={() => handleDelete(imageUrl)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentPreview;