import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Copy, Loader } from 'lucide-react';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import ImageCard from './ImageCard';
import useImageHandlers from '../hooks/image/useImageHandlers';
import '../css/ToolContent.css';
import useMessage from '../hooks/useNotify';

const ToolContent = ({
  toolTitle,
  apiEndpoint,
  formFields,
  imageName = 'ai_generated_image'
}) => {
  const [formData, setFormData] = useState({});
  const [generatedContent, setGeneratedContent] = useState('');
  const [generatedImageUrls, setGeneratedImageUrls] = useState([]);
  const [loadedImages, setLoadedImages] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [mobileView, setMobileView] = useState('content');
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { MessageDisplay, setMessage, clearMessage } = useMessage();

  const {
    buttonStates,
    error: imageHandlerError,
    handleDownload,
    handleCopyPrompt,
    clearError,
    // eslint-disable-next-line
    setError: setImageHandlerError
  } = useImageHandlers();

  useEffect(() => {
    if (imageHandlerError) {
      setError(imageHandlerError);
      clearError();
    }
  }, [imageHandlerError, clearError]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setGeneratedContent('');
    setGeneratedImageUrls([]);
    setLoadedImages({});
    setError(null);

    try {
      const response = await authenticatedApiCall(apiEndpoint, 'POST', formData);
      if (response.content && response.imageUrls) {
        setGeneratedContent(response.content);
        setGeneratedImageUrls(response.imageUrls);
      } else {
        throw new Error('No content or image URLs in the response');
      }
    } catch (error) {
      console.error('Error details:', error);
      setError(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageLoad = (index) => {
    setLoadedImages(prev => ({ ...prev, [index]: true }));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedContent).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    }, (err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const renderImageOrLoader = (imageUrl, index) => {
    if (loadedImages[index]) {
      return (
        <ImageCard
          key={index}
          image={{
            id: `generated-${index}`,
            imageUrl: imageUrl,
            title: `${imageName} ${index + 1}`,
            username: 'AI',
            is_liked: false
          }}
          index={index}
          copyPromptToClipboard={handleCopyPrompt}
          handleDownload={(url, id) => handleDownload(url, id, `${imageName}-${index + 1}`)}
          buttonStates={buttonStates}
          hideUserInfo={true}
          isToolGenerated={true}
        />
      );
    } else {
      return (
        <div key={index} className="tc-image-loader">
          <Loader size={24} className="animate-spin" />
          <img
            src={imageUrl}
            alt={`${imageName} ${index + 1}`}
            onLoad={() => handleImageLoad(index)}
            style={{ display: 'none' }}
          />
        </div>
      );
    }
  };

  const handleSave = async () => {
    if (!generatedContent && generatedImageUrls.length === 0) {
      setError("No content to save");
      return;
    }

    setIsSaving(true);
    setError(null);

    try {
      const response = await authenticatedApiCall('/save-content', 'POST', {
        tool_name: toolTitle,
        content: generatedContent,
        image_urls: generatedImageUrls
      });

      if (response.message === "Content saved successfully") {
        setSaveSuccess(true);
        setTimeout(() => setSaveSuccess(false), 2000);
      } else {
        throw new Error('Failed to save content');
      }
    } catch (error) {
      console.error('Error saving content:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setMessage(error.response.data.error, "error");
        setTimeout(() => {
          clearMessage();
        }, 3000);
      } else {
        setError(`Error: ${error.message}`);
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="tc-tool-content margin">
      <MessageDisplay />
      <p className='imgen-credit'>
        powered by
        <span><a href="https://groq.com/groqcloud/" target='_blank' rel='noreferrer noopener'>GroqCloud</a></span>
        &
        <span><a href="https://pollinations.ai" target='_blank' rel='noreferrer noopener'>Pollinations AI</a></span>
      </p>

      {/* Mobile View */}
      <div className="tc-mobile-view">
        <div className="tc-mobile-tabs">
          <button
            onClick={() => setMobileView('content')}
            className={`tc-tab-button ${mobileView === 'content' ? 'tc-active' : ''}`}
          >
            Content
          </button>
          <button
            onClick={() => setMobileView('images')}
            className={`tc-tab-button ${mobileView === 'images' ? 'tc-active' : ''}`}
          >
            Images
          </button>
        </div>
        <div className="tc-mobile-content">
          <h1 className="tc-title">{toolTitle}</h1>
          <div className={`tc-mobile-content-view ${mobileView === 'content' ? '' : 'tc-hidden'}`}>
            <form onSubmit={handleSubmit} className="tc-form">
              {formFields.map((field) => (
                <div key={field.name}>
                  {field.type === 'select' ? (
                    <select
                      name={field.name}
                      value={formData[field.name] || ''}
                      onChange={handleInputChange}
                      required={field.required}
                      className="tc-select-input"
                    >
                      <option value="" disabled>{field.placeholder}</option>
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name] || ''}
                      onChange={handleInputChange}
                      required={field.required}
                      className="tc-text-input"
                    />
                  )}
                </div>
              ))}
              <button
                type="submit"
                disabled={isLoading}
                className="tc-submit-button"
              >
                {isLoading ? 'Generating...' : 'Generate'}
              </button>
              <button
                onClick={handleSave}
                disabled={isLoading || isSaving || (!generatedContent && generatedImageUrls.length === 0)}
                className="tc-save-button"
              >
                {isSaving ? <Loader size={16} className="animate-spin" /> : ''}
                <span>{saveSuccess ? 'Saved!' : 'Save'}</span>
              </button>
            </form>
            {isLoading && (
              <div className="tc-loading-spinner">
                <div className="tc-spinner"></div>
              </div>
            )}
            {error && <div className="tc-error-message">{error}</div>}
            {!isLoading && !error && generatedContent && (
              <div className="tc-generated-content">
                <div className="tc-markdown-content">
                  <ReactMarkdown>{generatedContent}</ReactMarkdown>
                </div>
                <div className="tc-copy-button-container">
                  <button
                    onClick={handleCopy}
                    className="tc-copy-button"
                  >
                    <Copy size={16} />
                    <span>{copySuccess ? 'Copied!' : 'Copy'}</span>
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className={`tc-mobile-images-view ${mobileView === 'images' ? '' : 'tc-hidden'}`}>
            {generatedImageUrls.map((imageUrl, index) => (
              <ImageCard
                key={index}
                image={{
                  id: `generated-${index}`,
                  imageUrl: imageUrl,
                  title: `${imageName} ${index + 1}`,
                  username: 'AI',
                  is_liked: false
                }}
                index={index}
                copyPromptToClipboard={handleCopyPrompt}
                handleDownload={(url, id) => handleDownload(url, id, `${imageName}-${index + 1}`)}
                buttonStates={buttonStates}
                hideUserInfo={true}
                isToolGenerated={true}
              />
            ))}
          </div>
        </div>
      </div>
      {/* Desktop View */}
      <div className="tc-desktop-view">
        {/* Left Sidebar - Form Fields */}
        <div className="tc-sidebar left-sidebar">
          <h1 className="tc-title">{toolTitle}</h1>
          <form onSubmit={handleSubmit} className="tc-form">
            {formFields.map((field) => (
              <div key={field.name}>
                {field.type === 'select' ? (
                  <select
                    name={field.name}
                    value={formData[field.name] || ''}
                    onChange={handleInputChange}
                    required={field.required}
                    className="tc-select-input"
                  >
                    <option value="" disabled>{field.placeholder}</option>
                    {field.options.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    value={formData[field.name] || ''}
                    onChange={handleInputChange}
                    required={field.required}
                    className="tc-text-input"
                  />
                )}
              </div>
            ))}
            <button
              type="submit"
              disabled={isLoading}
              className="tc-submit-button"
            >
              {isLoading ? 'Generating...' : 'Generate'}
            </button>
            <button
              onClick={handleSave}
              disabled={isLoading || isSaving || (!generatedContent && generatedImageUrls.length === 0)}
              className="tc-save-button"
            >
              {isSaving ? <Loader size={16} className="animate-spin" /> : ''}
              <span>{saveSuccess ? 'Saved!' : 'Save'}</span>
            </button>
          </form>
        </div>

        {/* Center Content - Generated Content */}
        <div className="tc-main-content">
          {isLoading && (
            <div className="tc-loading-spinner">
              <div className="tc-spinner"></div>
            </div>
          )}
          {error && <div className="tc-error-message">{error}</div>}
          {!isLoading && !error && (
            <div className="tc-generated-content">
              <div className="tc-markdown-content">
                <ReactMarkdown>{generatedContent}</ReactMarkdown>
              </div>
              {generatedContent && (
                <div className="tc-copy-button-container">
                  <button
                    onClick={handleCopy}
                    className="tc-copy-button"
                  >
                    <Copy size={16} />
                    <span>{copySuccess ? 'Copied!' : 'Copy'}</span>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        {/* Right Sidebar - Generated Images */}
        <div className="tc-sidebar right-sidebar">
          <h2 className="tc-subtitle">Generated Images</h2>
          <div className="tc-image-grid">
            {generatedImageUrls.map((imageUrl, index) => renderImageOrLoader(imageUrl, index))}
          </div>
        </div>
      </div>
      {error && <div className="tc-error-message">{error}</div>}
    </div>
  );
};

export default ToolContent;