import React, { useState, useEffect } from 'react';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import ContentPreview from './ContentPreview';
import '../css/ContentViewer.css';

const ContentViewer = () => {
    const [activeTab, setActiveTab] = useState('Stories');
    const [viewMode, setViewMode] = useState('All');
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedContent, setSelectedContent] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const [isEditing, setIsEditing] = useState(false);

    const tabs = [
        { name: 'Stories', toolName: 'Story Generator' },
        { name: 'Blogs', toolName: 'Blog Generator' },
        { name: 'Recipes', toolName: 'Recipe Generator' },
        { name: 'Interviews', toolName: 'Interview Preparator' },
        { name: 'Diet', toolName: 'Diet Planner' },
        { name: 'Workouts', toolName: 'Workout Planner' },
        { name: 'Assignments', toolName: 'Assignment Maker' },
        { name: 'Startup Names', toolName: 'Startup Name Generator' },
        { name: 'Domain Ideas', toolName: 'Domain Name Generator' },
        { name: 'Nicknames', toolName: 'Nickname Generator' },
        { name: 'Baby Names', toolName: 'Baby Name Generator' },
        { name: 'Gifts', toolName: 'Gift Suggestion Generator' },
        { name: 'Dreams', toolName: 'Dream Analysis' },
        { name: 'Hobbies', toolName: 'Hobby Finder' },
        { name: 'Pet Names', toolName: 'Pet Name Generator' },
        { name: 'Startup Ideas', toolName: 'Startup Idea Generator' },
    ];

    useEffect(() => {
        setContent([]);
        setLoading(true);
        fetchContent(activeTab, viewMode, 1);
        // eslint-disable-next-line
    }, [activeTab, viewMode]);

    const fetchContent = async (tab, mode, pageNum, append = false) => {
        setError(null);
        const toolName = tabs.find(t => t.name === tab)?.toolName;

        try {
            const endpoint = mode === 'All' ? '/content/public' : '/content';
            const encodedToolName = encodeURIComponent(toolName);
            const response = await authenticatedApiCall(
                `${endpoint}/${encodedToolName}?page=${pageNum}&per_page=${pageNum === 1 ? 15 : 10}`,
                'GET'
            );
            if (response.contents && Array.isArray(response.contents)) {
                setContent(prevContent => append ? [...prevContent, ...response.contents] : response.contents);
                setHasMore(response.total_count > (pageNum * (pageNum === 1 ? 15 : 10)));
                setPage(pageNum);
            } else {
                throw new Error('Unexpected response format');
            }
        } catch (err) {
            console.error('Error fetching content:', err);
            setError('Error fetching content. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleLoadMore = () => {
        fetchContent(activeTab, viewMode, page + 1, true);
    };

    const handleContentClick = (item, startEditing = false) => {
        setSelectedContent(item);
        setIsEditing(startEditing);
    };

    const handleClosePreview = () => {
        setSelectedContent(null);
        setIsEditing(false);
    };

    const handleEditContent = async (id, editedContent) => {
        try {
            await authenticatedApiCall(`/content/${id}`, 'PUT', { content: editedContent });
            setContent(prevContent => prevContent.map(item =>
                item._id.$oid === id ? { ...item, content: editedContent } : item
            ));
        } catch (err) {
            console.error('Error editing content:', err);
            setError(`Error editing content: ${err.message}`);
        }
    };

    const handleDeleteContent = async (id) => {
        try {
            await authenticatedApiCall(`/content/${id}`, 'DELETE');
            setContent(prevContent => prevContent.filter(item => item._id.$oid !== id));
        } catch (err) {
            console.error('Error deleting content:', err);
            setError(`Error deleting content: ${err.message}`);
        }
    };

    const SkeletonCard = () => (
        <div className="cp-content-card cp-skeleton">
            <div className="cp-skeleton-image"></div>
            <div className="cp-skeleton-text"></div>
            <div className="cp-skeleton-text cp-skeleton-text-short"></div>
        </div>
    );

    const boldAsterisks = (text) => {
        const parts = text.split(/(\*\*[^*]+\*\*)/);
        let firstBolded = false;

        return parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                if (!firstBolded) {
                    firstBolded = true;
                    return (
                        <React.Fragment key={index}>
                            <strong className='cp-card-title'>{part.slice(2, -2)}</strong><br />
                        </React.Fragment>
                    );
                } else {
                    return part.slice(2, -2);
                }
            }
            return part;
        });
    };


    return (
        <div className="cp-content-viewer">
            <div className="cp-tab-buttons">
                {tabs.map((tab) => (
                    <button
                        key={tab.name}
                        onClick={() => {
                            if (!loading) {
                                setActiveTab(tab.name);
                                setPage(1);
                                setHasMore(true);
                            }
                        }}
                        className={`cp-tab-button ${activeTab === tab.name ? 'cp-active' : ''}`}
                        disabled={loading}
                    >
                        {tab.name}
                    </button>
                ))}
            </div>
            <div className="cp-view-mode-buttons">
                <button
                    onClick={() => !loading && setViewMode('All')}
                    className={`cp-view-mode-button ${viewMode === 'All' ? 'cp-active' : ''}`}
                    disabled={loading}
                >
                    All
                </button>
                <button
                    onClick={() => !loading && setViewMode('Your')}
                    className={`cp-view-mode-button ${viewMode === 'Your' ? 'cp-active' : ''}`}
                    disabled={loading}
                >
                    Your
                </button>
            </div>
            {error && <p className="cp-error">{error}</p>}
            <div className="cp-content-grid">
                {loading ? (
                    [...Array(15)].map((_, index) => <SkeletonCard key={index} />)
                ) : (
                    content.map((item) => (
                        <div
                            key={item._id.$oid}
                            className="cp-content-card"
                        >
                            <div onClick={() => handleContentClick(item)}>
                                {item.image_urls && item.image_urls.length > 0 && (
                                    <img
                                        src={item.image_urls[0]}
                                        alt="Content preview"
                                        className="cp-card-image"
                                    />
                                )}
                                <p className="cp-card-content">
                                    {boldAsterisks(item.content.substring(0, 150) + '...')}
                                </p>
                                <p className="cp-card-date">
                                    Created at: {new Date(item.created_at.$date).toLocaleString()} <br />
                                    {item.updated_at && (
                                        <> Updated at: {new Date(item.updated_at.$date).toLocaleString()}</>
                                    )}
                                </p>
                                {viewMode === 'All' && (
                                    <p className="cp-card-author">By: {item.user_name || 'Anonymous'}</p>
                                )}
                            </div>
                            {viewMode === 'Your' && (
                                <div className="cp-card-buttons">
                                    <button
                                        className="cp-edit-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleContentClick(item, true);
                                        }}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="cp-delete-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteContent(item._id.$oid);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>

            {hasMore && !loading && (
                <button className="cp-load-more-button" onClick={handleLoadMore}>
                    Load More
                </button>
            )}

            {loading && page > 1 && (
                <div className="cp-content-grid">
                    {[...Array(10)].map((_, index) => <SkeletonCard key={index} />)}
                </div>
            )}


            {selectedContent && (
                <ContentPreview
                    content={selectedContent}
                    onClose={handleClosePreview}
                    onEdit={handleEditContent}
                    onDelete={handleDeleteContent}
                    isYourContent={viewMode === 'Your'}
                    startEditing={isEditing}
                />
            )}
        </div>
    );
};

export default ContentViewer;