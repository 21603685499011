import React, { useState, useEffect, useMemo } from 'react';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [userCount, setUserCount] = useState(0);
    const [activeUsers, setActiveUsers] = useState({
        last24Hours: 0,
        last3Days: 0,
        last7Days: 0,
        last30Days: 0
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [visibleColumns, setVisibleColumns] = useState({});

    const columns = [
        'id', 'google_id', 'email', 'username', 'name', 'profile_image_url',
        'is_public', 'followers', 'following', 'bio', 'social_ambassador',
        'created_at', 'last_active'
    ];

    useEffect(() => {
        fetchUsers();
        initializeVisibleColumns();
        // eslint-disable-next-line
    }, []);

    const initializeVisibleColumns = () => {
        const initialVisibleColumns = {};
        columns.forEach(column => {
            initialVisibleColumns[column] = column === 'email' || column === 'username' || column === 'created_at' || column === 'last_active';
        });
        setVisibleColumns(initialVisibleColumns);
    };

    const fetchUsers = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await authenticatedApiCall('/admin/users', 'GET');
            setUsers(response.users);
            setUserCount(response.user_count);
            setActiveUsers({
                last24Hours: response.active_users_24h || 0,
                last3Days: response.active_users_3d || 0,
                last7Days: response.active_users_7d || 0,
                last30Days: response.active_users_30d || 0
            });
        } catch (err) {
            setError('Failed to fetch users. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedUsers = useMemo(() => {
        let sortableItems = [...users];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                if (sortConfig.key === 'created_at' || sortConfig.key === 'last_active') {
                    const dateA = a[sortConfig.key] ? new Date(a[sortConfig.key]) : new Date(0);
                    const dateB = b[sortConfig.key] ? new Date(b[sortConfig.key]) : new Date(0);
                    return sortConfig.direction === 'ascending' ? dateA - dateB : dateB - dateA;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                }
            });
        }
        return sortableItems;
    }, [users, sortConfig]);

    const filteredUsers = sortedUsers.filter(user =>
        Object.entries(user).some(([key, value]) =>
            key !== 'badges' && value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const truncateText = (text, maxLength = 30) => {
        if (text && text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString();
    };

    const toggleColumnVisibility = (column) => {
        setVisibleColumns(prev => ({ ...prev, [column]: !prev[column] }));
    };

    const renderContent = () => {
        if (loading) return <div className="ad-loading">Loading...</div>;
        if (error) return <div className="ad-error">{error}</div>;
        if (!filteredUsers.length) return <div className="ad-no-data">No users found</div>;

        return (
            <div className="ad-table-container">
                <div className="ad-column-toggles">
                    {columns.map(column => (
                        <label key={column} className="ad-column-toggle">
                            <input
                                type="checkbox"
                                checked={visibleColumns[column]}
                                onChange={() => toggleColumnVisibility(column)}
                            />
                            {column.charAt(0).toUpperCase() + column.slice(1).replace('_', ' ')}
                        </label>
                    ))}
                </div>
                <table className="ad-table">
                    <thead>
                        <tr>
                            {columns.filter(column => visibleColumns[column]).map((column) => (
                                <th key={column} onClick={() => handleSort(column)}>
                                    {column.charAt(0).toUpperCase() + column.slice(1).replace('_', ' ')}
                                    {sortConfig.key === column && (
                                        <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                                    )}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map((user) => (
                            <tr key={user.id}>
                                {columns.filter(column => visibleColumns[column]).map((column) => (
                                    <td key={column} title={user[column] !== undefined ? user[column].toString() : 'N/A'}>
                                        {column === 'social_ambassador'
                                            ? (user.badges && user.badges.social_ambassador !== undefined
                                                ? user.badges.social_ambassador.toString()
                                                : 'N/A')
                                            : column === 'created_at' || column === 'last_active'
                                                ? formatDate(user[column])
                                                : Array.isArray(user[column])
                                                    ? user[column].length
                                                    : user[column] !== undefined
                                                        ? truncateText(user[column].toString())
                                                        : 'N/A'}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className="user-management">
            <h2 className="ad-dashboard-title">User Management</h2>
            <div className="ad-user-stats">
                <div className="ad-user-count">Total Users: {userCount}</div>
                <div className="ad-active-users">
                    <div>Active Users (last 24 hours): {activeUsers.last24Hours}</div>
                    <div>Active Users (last 3 days): {activeUsers.last3Days}</div>
                    <div>Active Users (last 7 days): {activeUsers.last7Days}</div>
                    <div>Active Users (last 30 days): {activeUsers.last30Days}</div>
                </div>
            </div>
            <div className="ad-search-bar">
                <input
                    type="text"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div className="ad-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default UserManagement;