import React from 'react';
import ToolContent from '../ToolContent';

const InterviewPreparator = () => {
  const formFields = [
    { name: 'jobTitle', type: 'text', placeholder: 'Job Title or Position:', required: true },
    { name: 'industry', type: 'text', placeholder: 'Industry or Field:', required: true },
    {
      name: 'experienceLevel',
      type: 'select',
      placeholder: 'Select Experience Level:',
      options: [
        { value: 'Entry-level', label: 'Entry-level' },
        { value: 'Mid-level', label: 'Mid-level' },
        { value: 'Senior', label: 'Senior' },
        { value: 'Manager', label: 'Manager' },
      ],
    },
    {
      name: 'interviewType',
      type: 'select',
      placeholder: 'Select Type of Interview:',
      options: [
        { value: 'Behavioral', label: 'Behavioral' },
        { value: 'Technical', label: 'Technical' },
        { value: 'Case Study', label: 'Case Study' },
        { value: 'Panel', label: 'Panel' },
      ],
    },
    { name: 'keySkills', type: 'text', placeholder: 'Key Skills or Areas of Focus:' },
    { name: 'questionCount', type: 'number', placeholder: 'No of Questions: 2 - 20', min: 2, max: 20 },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_INTERVIEW}
      toolTitle="Interview Preparator"
      imageName="interview_prepration"
    />
  );
};

export default InterviewPreparator;