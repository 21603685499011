import React from 'react';
import '../css/TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="tos-container margin">
      <h1 className="tos-title">Terms of Service</h1>
      <div className="tos-content">
        <section className="tos-section">
          <h2 className="tos-section-title">1. Acceptance of Terms</h2>
          <p className="tos-text">By accessing or using ai.devsaura.com, you agree to comply with and be bound by these Terms of Service.</p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">2. Description of Service</h2>
          <p className="tos-text">ai.devsaura.com provides AI-powered tools including blog generation, story writing, image generation and many more powered by GroqCloud and Pollinations AI.</p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">3. User Responsibilities</h2>
          <ul className="tos-list">
            <li>You are responsible for your use of the service and any content you create or share.</li>
            <li>You agree not to use the service for any illegal or unauthorized purpose.</li>
            <li>You must not violate any laws in your jurisdiction when using our service.</li>
          </ul>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">4. Intellectual Property</h2>
          <p className="tos-text">Content generated through our service also subject to the terms and conditions of the (Groq and Pollinations). Users are the owner of content they create but they are also responsible for ensuring they have the right to use any generated content.</p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">5. Limitation of Liability</h2>
          <p className="tos-text">ai.devsaura.com is provided "as is" without any warranties. We are not responsible for any damages resulting from your use of the service.</p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">6. Content Moderation and Removal</h2>
          <p className="tos-text">
            We reserve the right to monitor, review, and remove any content uploaded, created, or shared on ai.devsaura.com without prior notice, if we believe the content:
          </p>
          <ul className="tos-list">
            <li>Violates our terms, policies, or community standards;</li>
            <li>Is unlawful, inappropriate, or harmful to others;</li>
            <li>Compromises the security or integrity of our platform.</li>
          </ul>
          <p className="tos-text">
            By using our service, you acknowledge and agree that we have full discretion to remove content that we find in violation of these terms, and such removal may occur without any prior notification.
          </p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">7. Changes to Terms</h2>
          <p className="tos-text">We reserve the right to modify these terms at any time. Continued use of the service after changes constitutes acceptance of the new terms.</p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">8. Contact Information</h2>
          <p className="tos-text">For any questions regarding these terms, please contact us at <a href="mailto:tools@devsaura.com">tools@devsaura.com</a>.</p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
