import React, { useState, useEffect } from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import '../css/Flyer.css'

const Flyer = ({ data }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderLink = () => {
        const linkProps = {
            className: "f-link",
            to: data.link,
        };

        if (data.openInNewTab) {
            linkProps.target = "_blank";
            linkProps.rel = "noopener noreferrer";
        }

        return (
            <Link {...linkProps}>
                {data.buttonName} <ArrowRight className="ml-1" size={16} />
            </Link>
        );
    };

    return (
        <div className={`f-flyer ${isMobile ? 'f-mobile' : ''}`}>
            <img src={data.imageUrl} alt={data.title} className="f-img" />
            <div className="f-overlay">
                <div className="f-content">
                    <h3 className="f-title">{data.title}</h3>
                    <p className="f-description">{data.description}</p>
                    {renderLink()}
                </div>
            </div>
        </div>
    );
};

export default Flyer;