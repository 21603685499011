import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import { Lock, Unlock, LogOut, UserX, Loader, PencilIcon, UserPlus, UserMinus, Users, FileQuestion, InfoIcon, FileImage, Heart } from 'lucide-react';
import '../../css/Profile.css'
import SocialLinks from './SocialLinks';
import useLikeImage from '../../hooks/image/useLikeImage';
import useImageHandlers from '../../hooks/image/useImageHandlers';
import useProfileHandlers from '../../hooks/profile/useProfileHandlers';
import ImageCard from '../ImageCard';
import Masonry from 'react-masonry-css';
import useMessage from '../../hooks/useNotify';

const Profile = () => {
    const [profileData, setProfileData] = useState({
        imageUrl: null,
        fullName: '',
        email: '',
        username: '',
        bio: '',
        savedImageUrls: [],
        isPublic: true,
        followerCount: 0,
        followingCount: 0,
        isFollowing: false,
        socialLinks: {},
        badges: {}
    });

    const [isEditing, setIsEditing] = useState(false);
    const [editedProfileData, setEditedProfileData] = useState({});

    const breakpointColumnsObj = {
        default: 5,
        1200: 5,
        1024: 4,
        768: 3,
        576: 2,
        400: 2
    };

    const [isOwnProfile, setIsOwnProfile] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [showFollowers, setShowFollowers] = useState(false);
    const [showFollowing, setShowFollowing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const { username } = useParams();
    const isInitialMount = useRef(true);

    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    // eslint-disable-next-line
    const [likedImages, setLikedImages] = useState([]);
    const [showLikedImages, setShowLikedImages] = useState(false);
    const [isLoadingLikedImages, setIsLoadingLikedImages] = useState(false);

    const { MessageDisplay, setMessage, clearMessage } = useMessage();

    const {
        images,
        setImages: setDisplayedImages,
        handleLike,
        likeInProgress,
    } = useLikeImage(profileData.savedImageUrls);
    const {
        buttonStates,
        handleDownload,
        handleCopyPrompt,
        handleReport,
    } = useImageHandlers();

    const {
        isDeleting,
        followLoading,
        toggleProfileVisibility,
        handleDeleteImage,
        handleDeleteAccount,
        handleFollow,
        handleUnfollow,
    } = useProfileHandlers();

    useEffect(() => {
        if (showLikedImages) {
            setDisplayedImages(likedImages);
        } else {
            setDisplayedImages(profileData.savedImageUrls);
        }
    }, [showLikedImages, likedImages, profileData.savedImageUrls, setDisplayedImages]);

    const fetchFollowers = async () => {
        try {
            const response = await authenticatedApiCall(`/followers/${username || profileData.username}`, 'GET');
            setFollowers(response);
            setMessage(null, null);
        } catch (error) {
            setMessage('Error fetching followers', 'error');
        }
    };

    const fetchFollowing = async () => {
        try {
            const response = await authenticatedApiCall(`/following/${username || profileData.username}`, 'GET');
            setFollowing(response);
            setMessage(null, null);
        } catch (error) {
            setMessage('Error fetching following', 'error');
        }
    };

    const toggleFollowersList = () => {
        setShowFollowers(!showFollowers);
        setShowFollowing(false);
        if (!showFollowers && followers.length === 0) {
            fetchFollowers();
        }
    };

    const toggleFollowingList = () => {
        setShowFollowing(!showFollowing);
        setShowFollowers(false);
        if (!showFollowing && following.length === 0) {
            fetchFollowing();
        }
    };

    const handleFollowAction = async () => {
        const success = isFollowing
            ? await handleUnfollow(username)
            : await handleFollow(username);
        if (success) {
            setIsFollowing(!isFollowing);
            setProfileData(prev => ({
                ...prev,
                followerCount: prev.followerCount + (isFollowing ? -1 : 1)
            }));
            setMessage(isFollowing ? 'Unfollowed successfully' : 'Followed successfully', 'success');
        } else {
            setMessage('Failed to update follow status', 'error');
        }
    };

    const handleImageClick = (index) => {
        if (window.innerWidth <= 768) {
            setSelectedImageIndex(prevIndex => prevIndex === index ? null : index);
        } else {
            setSelectedImageIndex(index === selectedImageIndex ? null : index);
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest('.image-card')) {
                setSelectedImageIndex(null);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const fetchProfileDetails = async () => {
            setIsLoading(true);
            try {
                let response;
                if (username) {
                    response = await authenticatedApiCall(`/get-user-profile?username=${username}`, 'GET');
                    setIsOwnProfile(false);
                    setIsFollowing(response.isFollowing);
                } else {
                    response = await authenticatedApiCall('/get-profile-details', 'GET');
                    setIsOwnProfile(true);
                }
                setProfileData(response);
                setEditedProfileData(response);
                setDisplayedImages(response.savedImageUrls);
            } catch (error) {
                console.error('Error fetching profile details:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (isInitialMount.current) {
            isInitialMount.current = false;
            fetchProfileDetails();
        } else if (username) {
            fetchProfileDetails();
        }
    }, [username, setDisplayedImages]);

    const fetchLikedImages = async () => {
        if (isLoadingLikedImages) return;

        setIsLoadingLikedImages(true);
        try {
            const response = await authenticatedApiCall('/get-liked-images', 'GET');
            setDisplayedImages(response);
        } catch (error) {
            setMessage('Error fetching liked images', 'error');
        } finally {
            setIsLoadingLikedImages(false);
        }
    };

    const toggleImageDisplay = () => {
        if (!showLikedImages) {
            fetchLikedImages();
        } else {
            setDisplayedImages(profileData.savedImageUrls);
        }
        setShowLikedImages(!showLikedImages);
    };

    const handleEditProfile = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditedProfileData(profileData);
    };

    const handleSaveProfile = async () => {
        try {
            const response = await authenticatedApiCall('/update-profile', 'POST', editedProfileData);
            if (response.success) {
                setProfileData(editedProfileData);
                setIsEditing(false);
                setMessage('Profile updated successfully', 'success');
                setTimeout(() => {
                    clearMessage();
                }, 3000);
            } else {
                setMessage(response.error, 'error');
                setTimeout(() => {
                    clearMessage();
                }, 3000);
            }
        } catch (error) {
            setMessage('Failed to update profile', 'error');
            setTimeout(() => {
                clearMessage();
            }, 3000);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedProfileData(prev => ({ ...prev, [name]: value }));
    };
    const handleSocialLinksChange = (newSocialLinks) => {
        setEditedProfileData(prev => ({ ...prev, socialLinks: newSocialLinks }));
    };

    const toggleProfileVisibilityWrapper = async () => {
        const newVisibility = await toggleProfileVisibility();
        if (newVisibility !== null) {
            setProfileData(prevData => ({
                ...prevData,
                isPublic: newVisibility
            }));
            setMessage(`Profile visibility set to ${newVisibility ? 'public' : 'private'}`, 'success');
        } else {
            setMessage('Failed to update profile visibility', 'error');
        }
    };

    const handleDeleteImageWrapper = async (imageId, index) => {
        const confirmed = window.confirm("Are you sure you want to delete this image? This action cannot be undone.");
        if (!confirmed) return;

        const success = await handleDeleteImage(imageId);
        if (success) {
            setProfileData(prevData => ({
                ...prevData,
                savedImageUrls: prevData.savedImageUrls.filter(img => img.id !== imageId)
            }));
            setMessage('Image deleted successfully', 'success');
        } else {
            setMessage('Failed to delete image', 'error');
        }
    };

    const handleDeleteAccountWrapper = async () => {
        if (window.confirm("Are you sure you want to delete your account? All of your generated art will be deleted permanently. This action cannot be undone.")) {
            const success = await handleDeleteAccount();
            if (success) {
                localStorage.removeItem('token');
                navigate('/login');
                setMessage('Account deleted successfully', 'success');
            } else {
                setMessage('Failed to delete account', 'error');
            }
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.replace('/login');
    };

    const handleCopyPromptWrapper = async (imageUrl, imageName) => {
        const result = await handleCopyPrompt(imageUrl, imageName);
        setMessage(result.message, result.success ? 'success' : 'error');
    };

    const handleDownloadWrapper = async (url, id, title) => {
        const result = await handleDownload(url, id, title);
        setMessage(result.message, result.success ? 'success' : 'error');
    };

    const renderProfileSkeleton = () => (
        <div className="profile-profile-card">
            <div className="profile-profile-header">
                <div className="profile-skeleton profile-skeleton-profile-image"></div>
                <div className="profile-profile-info">
                    <div className="profile-skeleton profile-skeleton-text profile-skeleton-name"></div>
                    <div className="profile-skeleton profile-skeleton-text profile-skeleton-username"></div>
                    <div className="profile-skeleton profile-skeleton-text profile-skeleton-email"></div>
                    <div className="profile-skeleton profile-skeleton-text profile-skeleton-bio"></div>
                    <div className="profile-stats">
                        <div className="profile-skeleton profile-skeleton-text profile-skeleton-button"></div>
                        <div className="profile-skeleton profile-skeleton-text profile-skeleton-button"></div>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderImagesSkeleton = () => (
        <div className="profile-album-section">
            <div className="profile-album-header">
                <h2 className="profile-album-title">Generated Images</h2>
            </div>
            <div className="profile-image-grid">
                {[...Array(6)].map((_, index) => (
                    <div key={index} className="profile-skeleton profile-skeleton-image"></div>
                ))}
            </div>
        </div>
    );

    if (isLoading) {
        return (
            <div className="profile-profile-container margin">
                {renderProfileSkeleton()}
                {renderImagesSkeleton()}
            </div>
        );
    }

    return (
        <div className="profile-profile-container margin">
            <MessageDisplay />
            <div className="profile-profile-card">
                <div className="profile-profile-header">
                    <div className={`profile-image-container ${profileData.badges?.social_ambassador ? 'social-ambassador' : ''}`}>
                        <img src={profileData.imageUrl || '/default-avatar.png'} alt="" className="profile-profile-image" />
                        {profileData.badges?.social_ambassador && (
                            <span
                                className="badge-icon"
                                title='social ambassador badge'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="20" height="20">
                                    <path d="m15,12c0,1.302-.839,2.402-2,2.816v-3.816h-2v3.816c-1.161-.414-2-1.514-2-2.816,0-1.708,1.819-3.67,3-4.708,1.181,1.038,3,3,3,4.708Zm7.08,4.175c.122,1.592-.451,3.167-1.595,4.31-1.144,1.143-2.711,1.718-4.31,1.595-1.039,1.212-2.558,1.92-4.175,1.92s-3.136-.708-4.175-1.92c-1.595.12-3.166-.452-4.31-1.595-1.144-1.144-1.717-2.718-1.595-4.31-1.212-1.039-1.92-2.558-1.92-4.175s.708-3.136,1.92-4.175c-.122-1.592.451-3.167,1.595-4.31,1.144-1.143,2.717-1.717,4.31-1.595,1.039-1.212,2.558-1.92,4.175-1.92s3.136.708,4.175,1.92c1.595-.121,3.167.452,4.31,1.595,1.144,1.144,1.717,2.718,1.595,4.31,1.212,1.039,1.92,2.558,1.92,4.175s-.708,3.136-1.92,4.175Zm-5.08-4.175c0-3.402-3.95-6.462-4.4-6.8l-.6-.45-.6.45c-.45.337-4.4,3.398-4.4,6.8,0,2.414,1.721,4.434,4,4.899v2.101h2v-2.101c2.279-.465,4-2.484,4-4.899Z" />
                                </svg>
                            </span>
                        )}
                    </div>
                    <div className="profile-profile-info">
                        {isEditing ? (
                            <div className="profile-edit-form">
                                <input
                                    type="text"
                                    name="fullName"
                                    value={editedProfileData.fullName}
                                    onChange={handleInputChange}
                                    placeholder="Full Name (letters & spaces only)"
                                />
                                <input
                                    type="text"
                                    name="username"
                                    value={editedProfileData.username}
                                    onChange={handleInputChange}
                                    placeholder="Username (4-12, unique, lowercase or numbers)"
                                />
                                <textarea
                                    name="bio"
                                    value={editedProfileData.bio}
                                    onChange={handleInputChange}
                                    placeholder="Bio (max 50 words/300 characters)"
                                />
                                <SocialLinks
                                    socialLinks={editedProfileData.socialLinks}
                                    isEditing={true}
                                    onSocialLinksChange={handleSocialLinksChange}
                                />
                                <div className="profile-edit-buttons">
                                    <button onClick={handleSaveProfile}>Save</button>
                                    <button onClick={handleCancelEdit}>Cancel</button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <h1 className="profile-profile-name">{profileData.fullName}</h1>
                                <p className="profile-profile-username">@{profileData.username}</p>
                                <p className="profile-profile-email">{profileData.email}</p>
                                <div className="profile-bio">
                                    <p>{profileData.bio || "No bio yet."}</p>
                                </div>
                                <SocialLinks
                                    socialLinks={profileData.socialLinks}
                                    isEditing={false}
                                />
                            </>
                        )}
                        <div className="profile-stats">
                            <button className="profile-stat-button" onClick={toggleFollowersList}>
                                <Users size={20} />
                                <span>{profileData.followerCount} Followers</span>
                            </button>
                            <button className="profile-stat-button" onClick={toggleFollowingList}>
                                <Users size={20} />
                                <span>{profileData.followingCount} Following</span>
                            </button>
                        </div>
                        {showFollowers && (
                            <div className="followers-list">
                                <h3>Followers</h3>
                                {followers.map((follower) => (
                                    <Link
                                        key={follower.username}
                                        to={`/profile/${follower.username}`}
                                        className="follower-item"
                                        onClick={() => {
                                            setShowFollowers(false);
                                            setShowFollowing(false);
                                        }}
                                    >
                                        <img
                                            src={follower.profile_image_url || '/default-avatar.png'}
                                            alt={follower.username}
                                            className="follower-image"
                                        />
                                        <span>{follower.username || follower.name}</span>
                                    </Link>
                                ))}
                            </div>
                        )}
                        {showFollowing && (
                            <div className="following-list">
                                <h3>Following</h3>
                                {following.map((followed) => (
                                    <Link
                                        key={followed.username}
                                        to={`/profile/${followed.username}`}
                                        className="following-item"
                                        onClick={() => {
                                            setShowFollowing(false);
                                            setShowFollowers(false);
                                        }}
                                    >
                                        <img
                                            src={followed.profile_image_url || '/default-avatar.png'}
                                            alt={followed.username}
                                            className="following-image"
                                        />
                                        <span>{followed.username || followed.name}</span>
                                    </Link>
                                ))}
                            </div>
                        )}
                        {!isOwnProfile && (
                            <button
                                className={`profile-follow-button ${isFollowing ? 'following' : ''}`}
                                onClick={handleFollowAction}
                                disabled={followLoading}
                            >
                                {followLoading ? (
                                    <Loader size={20} className="animate-spin" />
                                ) : isFollowing ? (
                                    <>
                                        <UserMinus size={20} />
                                        <span>Unfollow</span>
                                    </>
                                ) : (
                                    <>
                                        <UserPlus size={20} />
                                        <span>Follow</span>
                                    </>
                                )}
                            </button>
                        )}
                        <div className="profile-seperator-line"></div>
                        {isOwnProfile && (
                            <div className='profile-actions-container'>
                                <div className="profile-visibility-toggle">
                                    {!isEditing && (
                                        <button onClick={handleEditProfile} className="profile-icon-button" title="Edit Profile">
                                            <PencilIcon size={20} />
                                        </button>
                                    )}
                                    <button
                                        onClick={toggleProfileVisibilityWrapper}
                                        className="profile-icon-button"
                                        title={profileData.isPublic ? "Lock profile: Profile will not appear in explore and saved album will be hidden" : "Unlock profile: Profile may appear in explore and people can see your saved album"}
                                    >
                                        {profileData.isPublic ? <Unlock size={20} /> : <Lock size={20} />}
                                    </button>
                                    <button onClick={handleLogout} className="profile-icon-button" title="Logout Account">
                                        <LogOut size={20} />
                                    </button>
                                </div>
                                <div className="delete-account-button">
                                    <Link to="/" className="profile-icon-button" title="About Us">
                                        <InfoIcon size={20} />
                                    </Link>
                                    <Link to="/blog" className="profile-icon-button" title="Questions">
                                        <FileQuestion size={20} />
                                    </Link>
                                    <button
                                        onClick={handleDeleteAccountWrapper}
                                        disabled={isDeleting}
                                        className="profile-icon-button delete"
                                        title="Delete Account Permanently"
                                    >
                                        <UserX size={20} />
                                    </button>
                                </div>
                            </div>
                        )}
                        {!profileData.isPublic && !isOwnProfile && (
                            <p className="profile-private-profile-message">This profile is Locked!</p>
                        )}
                    </div>
                </div>
            </div>
            {(isOwnProfile || profileData.isPublic) && (
                <div className="profile-album-section">
                    <div className="profile-album-header">
                        <h2 className="profile-album-title">
                            {showLikedImages ? "Liked Images" : "Generated Images"}
                        </h2>
                        {isOwnProfile && (
                            <button onClick={toggleImageDisplay} className="profile-toggle-button" disabled={isLoadingLikedImages}>
                                {isLoadingLikedImages ? (
                                    <Loader size={20} className="animate-spin" />
                                ) : showLikedImages ? (
                                    <FileImage size={20} />
                                ) : (
                                    <Heart size={20} />
                                )}
                                <span>{showLikedImages ? "Show Generated" : "Show Liked"}</span>
                            </button>
                        )}
                    </div>
                    {images && images.length > 0 ? (
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {images.map((image, index) => (
                                <div key={image.id} className="profile-image-item">
                                    <ImageCard
                                        image={image}
                                        index={index}
                                        selectedImageIndex={selectedImageIndex}
                                        handleImageClick={handleImageClick}
                                        handleLike={handleLike}
                                        copyPromptToClipboard={handleCopyPromptWrapper}
                                        handleDownload={(url, id) => handleDownloadWrapper(url, id, image.title)}
                                        handleDelete={handleDeleteImageWrapper}
                                        handleReport={handleReport}
                                        buttonStates={buttonStates}
                                        likeInProgress={likeInProgress}
                                        isOwnProfile={isOwnProfile}
                                        isLikedImage={showLikedImages}
                                        hideUserInfo={true}
                                    />
                                </div>
                            ))}
                        </Masonry>
                    ) : (
                        <p className="profile-no-images-message">
                            {showLikedImages ? "No liked images yet." : "No saved images yet."}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Profile;