import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import { Search, RefreshCw, Loader } from 'lucide-react';
import '../../css/Explore.css';
import ImageCard from '../ImageCard';
import useLikeImage from '../../hooks/image/useLikeImage';
import useImageHandlers from '../../hooks/image/useImageHandlers';
import Masonry from 'react-masonry-css';

const UserCard = ({ user, onProfileClick }) => {
  return (
    <div className="explore-user-card" onClick={() => onProfileClick(user.username)}>
      <div className={`explore-profile-image-container ${user.badges?.social_ambassador ? 'social-ambassador' : ''}`}>
        <img src={user.profile_image_url || '/default-avatar.png'} alt="" className="explore-profile-image" />
        {user.badges?.social_ambassador && (
          <span
            className="explore-badge-icon"
            title='social ambassador badge'
          >
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="20" height="20">
              <path d="m15,12c0,1.302-.839,2.402-2,2.816v-3.816h-2v3.816c-1.161-.414-2-1.514-2-2.816,0-1.708,1.819-3.67,3-4.708,1.181,1.038,3,3,3,4.708Zm7.08,4.175c.122,1.592-.451,3.167-1.595,4.31-1.144,1.143-2.711,1.718-4.31,1.595-1.039,1.212-2.558,1.92-4.175,1.92s-3.136-.708-4.175-1.92c-1.595.12-3.166-.452-4.31-1.595-1.144-1.144-1.717-2.718-1.595-4.31-1.212-1.039-1.92-2.558-1.92-4.175s.708-3.136,1.92-4.175c-.122-1.592.451-3.167,1.595-4.31,1.144-1.143,2.717-1.717,4.31-1.595,1.039-1.212,2.558-1.92,4.175-1.92s3.136.708,4.175,1.92c1.595-.121,3.167.452,4.31,1.595,1.144,1.144,1.717,2.718,1.595,4.31,1.212,1.039,1.92,2.558,1.92,4.175s-.708,3.136-1.92,4.175Zm-5.08-4.175c0-3.402-3.95-6.462-4.4-6.8l-.6-.45-.6.45c-.45.337-4.4,3.398-4.4,6.8,0,2.414,1.721,4.434,4,4.899v2.101h2v-2.101c2.279-.465,4-2.484,4-4.899Z" />
            </svg>
          </span>
        )}
      </div>
      <div className="explore-user-card-names">
        <h3 className="explore-user-name">{user.name}</h3>
        <p className="explore-user-username">@{user.username}</p>
      </div>
    </div>
  );
};


const TagChip = ({ tag, onTagClick }) => {
  return (
    <div className="explore-tag-chip" onClick={() => onTagClick(tag)}>
      <span>{tag}</span>
    </div>
  );
};

const Explore = () => {
  const [users, setUsers] = useState([]);
  const [tags, setTags] = useState([]);
  const [images, setImages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [searchMessage, setSearchMessage] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const isInitialMount = useRef(true);
  const lastSearchTerm = useRef('');

  const breakpointColumnsObj = {
    default: 5,
    1200: 5,
    1024: 4,
    768: 3,
    576: 2,
    400: 1
  };

  const {
    images: likeImages,
    setImages: setLikeImages,
    handleLike,
    likeInProgress,
    error: likeError
  } = useLikeImage(images);

  const {
    buttonStates,
    handleDownload,
    handleCopyPrompt,
    handleReport,
    setError: setHandlerError
  } = useImageHandlers();

  useEffect(() => {
    setLikeImages(images);
  }, [images, setLikeImages]);

  useEffect(() => {
    if (likeError) {
      setHandlerError(likeError);
    }
  }, [likeError, setHandlerError]);

  const fetchRandomUsersAndTags = useCallback(async () => {
    if (isRefreshing) return;
    setIsRefreshing(true);
    try {
      const response = await authenticatedApiCall('/get-random-users', 'GET');
      setUsers(response.users);
      setTags(response.tags);
      setImages([]);
      setError(null);
    } catch (error) {
      setError('Not Connected!');
      setTimeout(() => setError(null), 2000);
    } finally {
      setIsRefreshing(false);
    }
  }, [isRefreshing]);

  const handleSearch = useCallback(async (query) => {
    if (!query.trim() || isSearching) return;
    setIsSearching(true);
    setSearchMessage('');
    try {
      const response = await authenticatedApiCall(`/search?query=${query}`, 'GET');
      setUsers(response.users);
      setImages(response.images);
      setError(null);
    } catch (error) {
      setError('Search failed');
      setUsers([]);
      setImages([]);
      setTimeout(() => setError(null), 4000);
    } finally {
      setIsSearching(false);
    }
  }, [isSearching]);

  useEffect(() => {
    const query = new URLSearchParams(location.search).get('query') || '';

    if (isInitialMount.current) {
      isInitialMount.current = false;
      lastSearchTerm.current = query;
      setSearchTerm(query);

      if (query) {
        handleSearch(query);
      } else {
        fetchRandomUsersAndTags();
      }
    } else if (query !== lastSearchTerm.current) {
      lastSearchTerm.current = query;
      setSearchTerm(query);

      if (query) {
        handleSearch(query);
      } else {
        fetchRandomUsersAndTags();
      }
    }
  }, [location.search, handleSearch, fetchRandomUsersAndTags]);

  const handleTagClick = (tag) => {
    setSearchTerm(tag);
    navigate(`/explore?query=${encodeURIComponent(tag)}`);
  };

  const handleProfileClick = (username) => {
    navigate(`/profile/${username}`);
  };

  const handleImageClick = (index) => {
    if (window.innerWidth <= 768) {
      setSelectedImageIndex(prevIndex => prevIndex === index ? null : index);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/explore?query=${encodeURIComponent(searchTerm)}`);
  };

  return (
    <div className="explore-explore-container margin">
      <h1 className="explore-explore-title">Explore Artists and Images!</h1>
      <form onSubmit={handleSearchSubmit} className="explore-search-container">
        <div className="explore-search-input-container">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search users or images"
            className="explore-search-input"
          />
        </div>
        <button
          type="submit"
          className="explore-search-button"
          disabled={isSearching}
        >
          {isSearching ? <Loader size={20} className="animate-spin" /> : <Search size={20} />}
        </button>
        <button
          type="button"
          onClick={fetchRandomUsersAndTags}
          className="explore-refresh-button"
          disabled={isRefreshing}
        >
          <RefreshCw size={19} className={isRefreshing ? "animate-spin" : ""} />
        </button>
      </form>
      {searchMessage && <div className="explore-search-message">{searchMessage}</div>}
      {error && <div className="explore-error-message">{error}</div>}
      {users.length > 0 && (
        <div>
          <h2 className='explore-users-heading'>Artists</h2>
          <div className="explore-user-grid">
            {users.map((user, index) => (
              <UserCard key={index} user={user} onProfileClick={handleProfileClick} />
            ))}
          </div>
        </div>
      )}
      {tags.length > 0 && (
        <div>
          <h2 className='explore-tags-heading'>Suggestions</h2>
          <div className="explore-tag-grid">
            {tags.map((tag, index) => (
              <TagChip key={index} tag={tag} onTagClick={handleTagClick} />
            ))}
          </div>
        </div>
      )}
      {likeImages.length > 0 && (
        <div>
          <h2 className='explore-images-heading'>Images</h2>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {likeImages.map((image, index) => (
              <ImageCard
                key={`${image.id}-${index}`}
                image={{
                  ...image,
                  is_liked: image.is_liked
                }}
                index={index}
                selectedImageIndex={selectedImageIndex}
                handleImageClick={handleImageClick}
                handleLike={handleLike}
                copyPromptToClipboard={handleCopyPrompt}
                handleDownload={handleDownload}
                handleReport={handleReport}
                buttonStates={buttonStates}
                likeInProgress={likeInProgress}
                isOwnProfile={false}
                showUserInfo={true}
              />
            ))}
          </Masonry>
        </div>
      )}
    </div>
  );
};

export default Explore;