import React, { useState, useEffect } from 'react';
import '../css/MessageDisplay.css'

const MessageDisplay = ({ message, type, duration = 3000 }) => {
  const [visible, setVisible] = useState(!!message);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [message, duration]);

  if (!visible) return null;

  return (
    <div className={`message-display ${type}`}>
      {message}
    </div>
  );
};

export const useMessage = (initialDuration = 3000) => {
  const [messageState, setMessageState] = useState({ text: null, type: null });
  const [duration, setDuration] = useState(initialDuration);

  const setMessage = (text, type, customDuration) => {
    setMessageState({ text, type });
    if (customDuration) setDuration(customDuration);
  };

  return {
    MessageDisplay: () => (
      <MessageDisplay
        message={messageState.text}
        type={messageState.type}
        duration={duration}
      />
    ),
    setMessage,
    clearMessage: () => setMessageState({ text: null, type: null }),
  };
};

export default useMessage;