import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import '../css/Blog.css';
import Footer from './Footer';

const Sidebar = ({ categories, isSidebarOpen, setIsSidebarOpen }) => {
    const [openCategories, setOpenCategories] = useState([]);

    const toggleCategory = (category) => {
        setOpenCategories(prev =>
            prev.includes(category)
                ? prev.filter(c => c !== category)
                : [...prev, category]
        );
    };

    return (
        <div className={`blog-sidebar ${isSidebarOpen ? 'open' : ''}`}>
            {Object.entries(categories).map(([category, items]) => (
                <div key={category} className={`blog-sidebar-category ${openCategories.includes(category) ? 'open' : ''}`}>
                    <h3 onClick={() => toggleCategory(category)}>
                        {category} 
                        <span className={`arrow ${openCategories.includes(category) ? 'open' : ''}`}>
                            &gt;
                        </span>
                    </h3>
                    <ul>
                        {items.map(item => (
                            <li key={item}>
                                <Link 
                                    to={`/blog/${category.toLowerCase()}/${item.toLowerCase().replace(/ /g, '-')}`}
                                    onClick={() => setIsSidebarOpen(false)}
                                >
                                    {item}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

const BlogContent = ({ content }) => {
    return (
        <div className="blog-content">
            <ReactMarkdown>{content}</ReactMarkdown>
        </div>
    );
};

const Blog = () => {
    const [blogContent, setBlogContent] = useState('');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { category, post } = useParams();
    // eslint-disable-next-line
    const navigate = useNavigate();

    const categories = {
        'Questions': ['FAQ', 'Common Issues', 'Troubleshooting'],
        'Tutorials': ['Getting Started', 'Design Logo'],
        'Other': ['Community', 'Advertisement', 'Content Instructions', 'Report Bug']
    };

    useEffect(() => {
        const loadBlogPost = async () => {
            if (category && post) {
                try {
                    const response = await fetch(`/blogs/${category}/${post}.md`);
                    if (!response.ok) {
                        throw new Error('Blog post not found');
                    }
                    const text = await response.text();
                    setBlogContent(text);
                } catch (error) {
                    console.error('Error loading blog post:', error);
                    setBlogContent('# Blog Post Not Found\n\nSorry, the requested blog post could not be found.');
                }
            } else {
                setBlogContent('# Welcome to Our Blog\n\nPlease select a post from the sidebar.');
            }
        };

        loadBlogPost();
    }, [category, post]);

    return (
        <>
            <div className="blog-container margin">
                <button className="sidebar-toggle" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    {isSidebarOpen ? '✕' : '☰'}
                </button>
                <Sidebar 
                    categories={categories} 
                    isSidebarOpen={isSidebarOpen} 
                    setIsSidebarOpen={setIsSidebarOpen}
                />
                <BlogContent content={blogContent} />
                <Footer />
            </div>
        </>
    );
};

export default Blog;